import { useLocation, Navigate } from "react-router-dom";
import React, { useCallback } from "react";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";

import { Button } from "rsuite";
import axios from "axios";
import { FileDownload } from "@mui/icons-material";
export function RequireAuth({ children }) {
  const user = useSelector((state) => state.nameReducer.user);
  console.log(user);
  //const [user, setUser] = React.useState(true)
  let location = useLocation();
  if (!user.uid) {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else {
    return children;
  }
}
export function Table2XLSX({
  tableID = "TableData",
  exportFileName = "Export",
  loading = false,
  setloading,
}) {
  const [processing, setProcessing] = React.useState(loading);
  /* Callback invoked when the button is clicked */
  const handleExport = useCallback(async () => {
    /* Create worksheet from HTML DOM TABLE */
    const table = document.getElementById(tableID);
    const wb = XLSX.utils.table_to_book(table);
    XLSX.utils.sheet_add_aoa(wb, [["Created " + new Date().toISOString()]], {
      origin: -1,
    });
    XLSX.writeFile(wb, `${exportFileName}.xlsx`);
  }, []);
  return (
    <Button
      color="info"
      appearance="primary"
      onClick={handleExport}
      loading={loading}
    >
      <FileDownload /> Export
    </Button>
  );
}

export function NewAPIAddress(add) {
  const Domain = "";
  return Domain + add + ".php";
}

export function ValidateEmail(inputText) {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (inputText.match(mailformat)) {
    return true;
  } else {
    return false;
  }
}

export function validateNumber(val) {
  var phoneno = /^\d{10}$/;
  if (val.match(phoneno)) {
    return true;
  } else {
    return false;
  }
}

export function validateOnlyNumber(val) {
  var no = /^\d$/;
  console.log(val.match(no));
  if (val.match(no)) {
    return true;
  } else {
    return false;
  }
}

export function number2text(value) {
  var fraction = Math.round(frac(value) * 100);
  var f_text = "";

  if (fraction > 0) {
    f_text = "AND " + convert_number(fraction) + " PAISE";
  }

  return convert_number(value) + " RUPEE(S) " + f_text + " ONLY";
}

function frac(f) {
  return f % 1;
}

function convert_number(number) {
  if (number < 0 || number > 999999999) {
    return "NUMBER OUT OF RANGE!";
  }
  var Gn = Math.floor(number / 10000000); /* Crore */
  number -= Gn * 10000000;
  var kn = Math.floor(number / 100000); /* lakhs */
  number -= kn * 100000;
  var Hn = Math.floor(number / 1000); /* thousand */
  number -= Hn * 1000;
  var Dn = Math.floor(number / 100); /* Tens (deca) */
  number = number % 100; /* Ones */
  var tn = Math.floor(number / 10);
  var one = Math.floor(number % 10);
  var res = "";

  if (Gn > 0) {
    res += convert_number(Gn) + " CRORE";
  }
  if (kn > 0) {
    res += (res === "" ? "" : " ") + convert_number(kn) + " LAKH";
  }
  if (Hn > 0) {
    res += (res === "" ? "" : " ") + convert_number(Hn) + " THOUSAND";
  }

  if (Dn) {
    res += (res === "" ? "" : " ") + convert_number(Dn) + " HUNDRED";
  }

  var ones = Array(
    "",
    "ONE",
    "TWO",
    "THREE",
    "FOUR",
    "FIVE",
    "SIX",
    "SEVEN",
    "EIGHT",
    "NINE",
    "TEN",
    "ELEVEN",
    "TWELVE",
    "THIRTEEN",
    "FOURTEEN",
    "FIFTEEN",
    "SIXTEEN",
    "SEVENTEEN",
    "EIGHTEEN",
    "NINETEEN"
  );
  var tens = Array(
    "",
    "",
    "TWENTY",
    "THIRTY",
    "FOURTY",
    "FIFTY",
    "SIXTY",
    "SEVENTY",
    "EIGHTY",
    "NINETY"
  );

  if (tn > 0 || one > 0) {
    if (!(res === "")) {
      res += " AND ";
    }
    if (tn < 2) {
      res += ones[tn * 10 + one];
    } else {
      res += tens[tn];
      if (one > 0) {
        res += "-" + ones[one];
      }
    }
  }

  if (res === "") {
    res = "zero";
  }
  return res;
}

export function sendFCMPushNotification(
  title,
  message,
  NotificationTo = "Support"
) {
  axios
    .post("AddNotification.php", {
      Data: message,
    })
    .then((response) => {
      const result = response.data;
      if (result.status === 1) {
      }
    })
    .catch((e) => {
      console.log(e);
    });
  const GetIDAddress =
    NotificationTo === "Support" ? "GetSupportID.php" : "GetAdminID.php";
  let RecipientID = "";
  axios
    .post(GetIDAddress, { App: "Attendance" })
    .then(async function (response) {
      const data = response.data;
      console.log(data);
      if (data.status === 1) {
        RecipientID = data.Data[0].DeviceID;
        console.log(RecipientID);
        try {
          fetch("https://fcm.googleapis.com/fcm/send", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `key=AAAAsobB6fk:APA91bGyVj3tiNfv4FxOzvaJXcRaRos1YrezXgduKTvT3OMvv-bAgJ-Nr8euaABG2FQSIqIkCt2Swh5J0y1zvhJhzkgPYdhi1XaJ2hPN1fd9uEgggjnA0rDpnwy8kz45wsuf07no4Qk3`,
            },
            body: JSON.stringify({
              to: RecipientID,
              priority: "normal",
              data: {
                title,
                message,
              },
            }),
          })
            .then((a) => {
              console.log(a);
            })
            .catch((e) => console.log(e));
        } catch (error) {}
      }
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {});
}

export function GetLastDay(date) {
  var year = date.getFullYear(),
    month = date.getMonth();
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);
  return lastDay;
}

function getOperatingSystem(window) {
  let operatingSystem = "Not known";
  if (window.navigator.appVersion.indexOf("Win") !== -1) {
    operatingSystem = "Windows OS";
  }
  if (window.navigator.appVersion.indexOf("Mac") !== -1) {
    operatingSystem = "MacOS";
  }
  if (window.navigator.appVersion.indexOf("X11") !== -1) {
    operatingSystem = "UNIX OS";
  }
  if (window.navigator.appVersion.indexOf("Linux") !== -1) {
    operatingSystem = "Linux OS";
  }

  return operatingSystem;
}

function getBrowser(window) {
  let currentBrowser = "Not known";
  if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
    currentBrowser = "Google Chrome";
  } else if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
    currentBrowser = "Mozilla Firefox";
  } else if (window.navigator.userAgent.indexOf("MSIE") !== -1) {
    currentBrowser = "Internet Exployer";
  } else if (window.navigator.userAgent.indexOf("Edge") !== -1) {
    currentBrowser = "Edge";
  } else if (window.navigator.userAgent.indexOf("Safari") !== -1) {
    currentBrowser = "Safari";
  } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
    currentBrowser = "Opera";
  } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
    currentBrowser = "YaBrowser";
  } else {
    console.log("Others");
  }

  return currentBrowser;
}

export const OS = (window) => getOperatingSystem(window);
export const currentBrowser = (window) => getBrowser(window);
