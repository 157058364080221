import axios from "axios";

const sendEmail = (
  To = "akv7@ymail.com",
  Body = "New Transactions",
  Subject = "Saved Successfully",
  headers = "This is a transactional email"
) => {
  axios
    .post(
      "https://api.ashaelectricals.in/SendEmail1.php",
      {
        To,
        Body,
        Subject,
        headers,
      },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    )
    .then(async function (response) {
      if (response.status !== 201) {
      } else {
      }
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {});
};

export default sendEmail;
