import React, { useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Radio,
  RadioGroup,
  SelectPicker,
} from "rsuite";

import Header from "../component/Header";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import axios from "axios";
import FilterTableComponent from "../component/TableNew";
import { Table2XLSX } from "../functions";

const ViewAllNew = () => {
  const [radio, setRadio] = useState("A");

  const [selectedDate, setselectedDate] = useState(new Date());
  const [selectedName, setselectedName] = useState("");
  const [selectedNameID, setselectedNameID] = useState("");

  const [datalist, setDataList] = useState([]);
  const [credit, setCredit] = useState(0);
  const [debit, setDebit] = useState(0);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [usersList, setUsersList] = useState([]);

  const [receiverList, setReceiverList] = useState([
    {
      label: "Eugenia",
      value: "Eugenia",
      role: "Master",
    },
    {
      label: "Kariane",
      value: "Kariane",
      role: "Master",
    },
    {
      label: "Louisa",
      value: "Louisa",
      role: "Master",
    },
  ]);

  //const dataNew = React.useMemo(handleGetCurrentMonth(), [])

  const [column, setColumn] = useState([
    { field: "Name", title: "Name" },
    { field: "Date", title: "Date" },
    { field: "Amount", title: "Amount" },
    { field: "Category", title: "Category" },
    { field: "Description", title: "Description" },
    { field: "RecipientType", title: "RecipientType" },
    { field: "SavedBy", title: "SavedBy" },
    { field: "Delete", title: "Delete" },
  ]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name", // accessor is the "key" in the data
      },
      {
        Header: "Date",
        accessor: "Date",
      },
      {
        Header: "Amount",
        accessor: "Amount",
      },
      {
        Header: "Category",
        accessor: "Category",
      },
      {
        Header: "Description",
        accessor: "Description",
      },
      {
        Header: "RecipientType",
        accessor: "RecipientType",
      },
      {
        Header: "SavedBy",
        accessor: "SavedBy",
      },
    ],
    []
  );

  const getRecieversList = () => {
    setReceiverList([]);
    axios
      .get("GetAllBillers.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          //setDataList(data1.Data)
          data1.Data.forEach((r) => {
            setReceiverList((a) => {
              return [...a, { id: r.UniqueID, value: r.Name, label: r.Name }];
            });
          });
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getUsersList = () => {
    setUsersList([]);
    axios
      .get("GetUsers.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          //setDataList(data1.Data)
          data1.Data.forEach((r) => {
            setUsersList((a) => {
              return [...a, { id: r.UniqueID, value: r.Name, label: r.Name }];
            });
          });
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function handleGetCurrentMonth() {
    //setDataList([]);
    axios
      .get("GetTransactionsForCurrentMonth.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setDataList(data1.Data);
          setTotal(data1.Sum);
          setCount(data1.Count);
          //return data1.Data
        }
        if (data1.status === 0) {
          //return []
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleMonthwise = () => {
    let datatoSend = { Month: selectedDate };
    axios
      .post("GetTransactionsForMonth.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setDataList(data1.Data);
          setTotal(data1.Sum);
          setCount(data1.Count);
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handlePartywise = () => {
    let datatoSend = { NameID: selectedNameID };
    axios
      .post("GetTransactionsByParty.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setDataList(data1.Data);
          setTotal(data1.Sum);
          setCount(data1.Count);
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSupwise = () => {
    setCredit(0);
    setDebit(0);
    setColumn([
      { field: "Name", title: "Name" },
      { field: "Date", title: "Date" },
      { field: "Amount", title: "Amount" },
      { field: "Category", title: "Category" },
      { field: "Description", title: "Description" },
      { field: "TransactionType", title: "Type" },
      { field: "SavedBy", title: "SavedBy" },
    ]);

    let datatoSend = { NameID: selectedNameID, Month: selectedDate };
    axios
      .post("GetTransactionBySup.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setDataList(data1.Data);
          setTotal(data1.Sum);
          setCredit(data1.IncomeSum);
          setDebit(data1.ExpenseSum);
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleRadio = (e) => {
    setRadio(e);
    if (e === "C") {
      getRecieversList();
    }
    if (e === "D") {
      getUsersList();
    }
  };

  function compare(a, b) {
    let nameA = a.toUpperCase();
    let nameB = b.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>View All</h4>
      </Header>
      <div className="InputContainer">
        <Form.Group controlId="radioList">
          <RadioGroup
            name="radioList"
            inline
            appearance="picker"
            defaultValue="A"
            onChange={(e) => {
              handleRadio(e);
            }}
            value={radio}
          >
            <Radio value="A">Current Month</Radio>
            <Radio value="B">Month Wise</Radio>
            <Radio value="C">Party View</Radio>
            <Radio value="D">Supervisor</Radio>
          </RadioGroup>
          &nbsp; &nbsp; &nbsp;
          <Table2XLSX />
          <hr />
        </Form.Group>

        <Form layout="inline">
          {radio === "A" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifuContent: "space-between",
                }}
              >
                <div style={{ flex: 1 }}>
                  <Button
                    color="blue"
                    appearance="default"
                    onClick={handleGetCurrentMonth}
                  >
                    Search
                  </Button>
                </div>
                <div style={{ flex: 1, justifyContent: "center" }}>
                  <div>
                    {`Total Amount : ${Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(total)}`}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    {`No of Transactions : ${count}`}
                  </div>
                </div>
              </div>
            </>
          )}
          {radio === "B" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifuContent: "space-between",
                }}
              >
                <div style={{ flex: 1 }}>
                  <Form.Group>
                    <Form.ControlLabel>Date:</Form.ControlLabel>
                    <DatePicker
                      oneTap
                      cleanable={false}
                      format="MMM-yyyy"
                      ranges={[]}
                      onSelect={(e) => setselectedDate(e)}
                      style={{ width: 200 }}
                    />
                  </Form.Group>
                  <Button
                    color="blue"
                    appearance="default"
                    onClick={handleMonthwise}
                  >
                    Search
                  </Button>
                </div>
                <div style={{ flex: 1, justifyContent: "center" }}>
                  <div>
                    {`Total Amount : ${Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(total)}`}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    {`No of Transactions : ${count}`}
                  </div>
                </div>
              </div>
            </>
          )}

          {radio === "C" && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifuContent: "space-between",
              }}
            >
              <div style={{ flex: 1 }}>
                <Form.Group>
                  <Form.ControlLabel>Party:</Form.ControlLabel>
                  <SelectPicker
                    cleanable={false}
                    data={receiverList}
                    appearance="default"
                    placeholder="Select Party"
                    style={{ width: 224 }}
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      console.log(Item);
                      setselectedName(e);
                      setselectedNameID(Item.id);
                    }}
                  />
                </Form.Group>
                <Button
                  color="blue"
                  appearance="default"
                  onClick={handlePartywise}
                >
                  Search
                </Button>
              </div>
              <div style={{ flex: 1, justifyContent: "center" }}>
                <div>
                  {`Total Amount : ${Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(total)}`}
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  {`No of Transactions : ${count}`}
                </div>
              </div>
            </div>
          )}

          {radio === "D" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifuContent: "space-between",
                }}
              >
                <div style={{ flex: 1 }}>
                  <Form.Group>
                    <Form.ControlLabel>Supervisor:</Form.ControlLabel>
                    &nbsp; &nbsp; &nbsp;
                    <SelectPicker
                      cleanable={false}
                      data={usersList}
                      appearance="default"
                      placeholder="Default"
                      style={{ width: 200 }}
                      key="id"
                      sort={() => {
                        return (a, b) => {
                          return compare(a.value, b.value);
                        };
                      }}
                      onSelect={(e, Item) => {
                        console.log(Item);
                        setselectedName(e);
                        setselectedNameID(Item.id);
                      }}
                      value={selectedName}
                    />
                    &nbsp; &nbsp; &nbsp;
                    <DatePicker
                      cleanable={false}
                      oneTap
                      format="MMM-yyyy"
                      ranges={[]}
                      onSelect={(e) => setselectedDate(e)}
                      style={{ width: 130 }}
                    />
                    &nbsp; &nbsp; &nbsp;
                    <Button
                      color="blue"
                      appearance="default"
                      onClick={handleSupwise}
                    >
                      Search
                    </Button>
                  </Form.Group>
                </div>
                <div style={{ flex: 1, justifyContent: "center" }}>
                  <div>
                    {`Credit : ${Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(credit)}`}
                    &nbsp;
                    {`Debit : ${Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(debit)}`}
                    &nbsp;
                    <h6
                      style={{
                        display: "inline-block",
                        color: credit < debit ? "red" : "limegreen",
                      }}
                    >
                      {`Balance : ${Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                      }).format(credit - debit)}`}
                    </h6>
                  </div>
                </div>
              </div>
            </>
          )}
        </Form>
      </div>

      <div className="InputContainer">
        {/* <Table id="TableData" striped bordered hover>
          <thead>
            <tr>
              {column.map((a) => (
                <th key={a.field}>{a.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {datalist.map((a) => (
              <tr key={a.SrNo}>
                <td>{a.Name}</td>
                <td>{a.Date}</td>
                <td>
                  {Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(a.Amount)}
                </td>
                <td>{a.Category}</td>
                <td>{a.Description}</td>
                <td>{radio === "D" ? a.TransactionType : a.RecipientType}</td>
                <td>{a.SavedBy}</td>
                <td width={60}>
                  <IconButton
                    icon={<TrashIcon />}
                    color="red"
                    appearance="primary"
                    circle
                    onClick={() =>
                      handleDelete(a.SrNo, {
                        Name: a.Name,
                        Date: a.Date,
                        Amount: a.Amount,
                      })
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table> */}
        <FilterTableComponent columns={columns} data={datalist} />
      </div>
    </div>
  );
};

export default ViewAllNew;
