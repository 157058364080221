import React, { useState } from "react";
import {
  Button,
  Form,
  Radio,
  RadioGroup,
  SelectPicker,
  DatePicker,
  useToaster,
  Notification,
  IconButton,
  Modal,
  Stack,
  Toggle,
  TagInput,
  TagPicker,
} from "rsuite";
import Header from "../component/Header";
import SearchIcon from "@rsuite/icons/Search";

import Table from "react-bootstrap/Table";
import moment from "moment";
import { FaClock } from "react-icons/fa";

import { AdminID } from "../component/data";

import { lastDay } from "../Constants";

import { Table2XLSX, sendFCMPushNotification } from "../functions";
import CalmButton from "../component/CalmButton";
import privateAxios from "../Axios/axiosConfig";
import { useParams } from "react-router-dom";

const combineDateTime = (date, time) => {
  const combinedDateTime = new Date(date);

  combinedDateTime.setHours(time.slice(0, 2));
  combinedDateTime.setMinutes(time.substring(3, 5));
  return combinedDateTime;
};
const MarkAttendance = () => {
  const [pending, setPending] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [text, setText] = useState("");
  const [desc, setDesc] = useState("");
  const [rate, setRate] = useState("");
  const [labour, setLabour] = useState("");
  const [radio, setRadio] = useState("A");
  const [selectedDate, setselectedDate] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [LabourList, setLabourList] = useState([]);
  const [dataList, setdataList] = useState([]);
  const [pendingDataList, setpendingDataList] = useState([]);
  const [dataList2, setdataList2] = useState([]);
  const [pendingDataList2, setpendingDataList2] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [processing1, setProcessing1] = useState(false);
  const [processing2, setProcessing2] = useState(false);
  const toaster = useToaster();
  const [usersList, setUsersList] = useState([]);
  const [selectedName, setselectedName] = useState("");
  const [selectedNameID, setselectedNameID] = useState("");

  const [selectedName1, setselectedName1] = useState("");
  const [selectedNameID1, setselectedNameID1] = useState("");

  const [selectedSupervisor, setselectedSupervisor] = useState("");
  const [selectedSupervisorID, setselectedSupervisorID] = useState("");

  const [inTime, setIntime] = useState(null);
  const [outTime, setOuttime] = useState(null);
  const [overTime, setOvertime] = useState(null);

  function compare(a, b) {
    let nameA = a.toUpperCase();
    let nameB = b.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }
  const [siteList, setSiteList] = useState([]);
  const [siteList1, setSiteList1] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [sup, setsup] = useState("");
  const [supID, setsupID] = useState("");
  const [attendance, setAttendance] = useState("");
  const [sitename, setSitename] = useState("");

  const [selectedDate1, setselectedDate1] = useState(new Date());

  let { Emp } = useParams();

  React.useEffect(() => {
    if (Emp) {
      setRadio("A");
    }
  }, []);
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      console.log(moment(inTime).format("HH"));
      if (
        moment(inTime).format("HH") >= 10 &&
        moment(inTime).format("mm") >= 30
      ) {
        if (moment(inTime).format("HH") >= 10) setDesc(0.5);
      } else setDesc(1);
    }

    return () => {
      isSubscribed = false;
    };
  }, [inTime]);

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      const OUTHour = moment(outTime).format("HH");
      const OUTMin = moment(outTime).format("mm");
      //console.log("type=>" + typeof OUTHour);
      if (OUTHour > 0 && OUTHour <= 8) {
        // console.log(OutTime);
        //setOutTime(new Date(moment(abc).add(1, "days")));
      }
      console.log(parseInt(OUTHour) === 4);
      if (parseInt(OUTHour) < 20 && parseInt(OUTHour) >= 9) {
        setOvertime("00:00:00");
      } else if (parseInt(OUTHour) === 20 && parseInt(OUTMin) < 30) {
        // console.log("2 hours");
        setOvertime("02:00:00");
      } else if (parseInt(OUTHour) === 20 && parseInt(OUTMin) >= 30) {
        setOvertime("03:00:00");
      } else if (parseInt(OUTHour) === 21) {
        if (parseInt(OUTMin) >= 30) {
          setOvertime("04:00:00");
        }
        if (parseInt(OUTMin) < 30) {
          setOvertime("03:00:00");
        }
      } else if (parseInt(OUTHour) === 22) {
        if (parseInt(OUTMin) >= 30) {
          setOvertime("05:00:00");
        }
        if (parseInt(OUTMin) < 30) {
          setOvertime("04:00:00");
        }
      } else if (parseInt(OUTHour) === 23) {
        if (parseInt(OUTMin) >= 30) {
          setOvertime("06:00:00");
        }
        if (parseInt(OUTMin) < 30) {
          setOvertime("05:00:00");
        }
      } else if (parseInt(OUTHour) === 0) {
        if (parseInt(OUTMin) >= 30) {
          setOvertime("07:00:00");
        }
        if (parseInt(OUTMin) < 30) {
          setOvertime("06:00:00");
        }
      } else if (parseInt(OUTHour) === 1) {
        if (parseInt(OUTMin) >= 30) {
          setOvertime("08:00:00");
        }
        if (parseInt(OUTMin) < 30) {
          setOvertime("07:00:00");
        }
      } else if (parseInt(OUTHour) === 2) {
        if (parseInt(OUTMin) >= 30) {
          setOvertime("09:00:00");
        }
        if (parseInt(OUTMin) < 30) {
          setOvertime("08:00:00");
        }
      } else if (parseInt(OUTHour) === 3) {
        if (parseInt(OUTMin) >= 30) {
          setOvertime("10:00:00");
        }
        if (parseInt(OUTMin) < 30) {
          setOvertime("09:00:00");
        }
      } else if (parseInt(OUTHour) === 4) {
        console.log("In 4");
        if (parseInt(OUTMin) >= 30) {
          setOvertime("11:00:00");
        }
        if (parseInt(OUTMin) < 30) {
          setOvertime("10:00:00");
        }
      } else if (parseInt(OUTHour) === 5) {
        if (parseInt(OUTMin) >= 30) {
          setOvertime("12:00:00");
        }
        if (parseInt(OUTMin) < 30) {
          setOvertime("11:00:00");
        }
      } else if (parseInt(OUTHour) === 6) {
        if (parseInt(OUTMin) >= 30) {
          setOvertime("13:00:00");
        }
        if (parseInt(OUTMin) < 30) {
          setOvertime("12:00:00");
        }
      } else if (parseInt(OUTHour) === 7) {
        if (parseInt(OUTMin) >= 30) {
          setOvertime("14:00:00");
        }
        if (parseInt(OUTMin) < 30) {
          setOvertime("13:00:00");
        }
      } else {
        console.log("more than 14");
      }
    }
    return () => {
      isSubscribed = false;
    };
  }, [outTime]);

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getLabourList();
      getSitesList();
      getUsersList();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  const column = [
    { field: "Date", title: "Date" },
    { field: "Name", title: "Name" },
    { field: "Attendance", title: "Attendance" },

    { field: "Rate", title: "Rate" },
    { field: "Salary", title: "Salary" },
    { field: "InTime", title: "InTime" },
    { field: "OutTime", title: "OutTime" },
    { field: "Overtime", title: "Overtime" },
    { field: "Total", title: "Total" },
    { field: "Supervisor", title: "Supervisor" },
    { field: "SiteName", title: "Site" },
  ];

  let i = 0;
  const Attendance = [
    { key: ++i, label: "Absent", value: 0 },
    { key: ++i, label: "Half Date", value: 0.5 },
    { key: ++i, label: "Full Day", value: 1 },
  ];
  const timeData = [
    { key: ++i, label: "00:00:00", value: "00:00:00" },
    { key: ++i, label: "01:00:00", value: "01:00:00" },

    { key: ++i, label: "02:00:00", value: "02:00:00" },

    { key: ++i, label: "03:00:00", value: "03:00:00" },

    { key: ++i, label: "04:00:00", value: "04:00:00" },

    { key: ++i, label: "05:00:00", value: "05:00:00" },

    { key: ++i, label: "06:00:00", value: "06:00:00" },
    { key: ++i, label: "07:00:00", value: "07:00:00" },
    { key: ++i, label: "08:00:00", value: "08:00:00" },
    { key: ++i, label: "09:00:00", value: "09:00:00" },
    { key: ++i, label: "10:00:00", value: "10:00:00" },
    { key: ++i, label: "11:00:00", value: "11:00:00" },
    { key: ++i, label: "12:00:00", value: "12:00:00" },
    { key: ++i, label: "13:00:00", value: "13:00:00" },
    { key: ++i, label: "14:00:00", value: "14:00:00" },
  ];
  const column2 = [
    { field: "Name", title: "Name" },
    { field: "Attendance", title: "Attendance" },

    { field: "Rate", title: "Rate" },
    { field: "Salary", title: "Salary" },
    { field: "InTime", title: "InTime" },
    { field: "OutTime", title: "OutTime" },
    { field: "Overtime", title: "Overtime" },
    { field: "Total", title: "Total" },
    { field: "Supervisor", title: "Supervisor" },
    { field: "SiteName", title: "Site" },
  ];

  const getSitesList = () => {
    privateAxios
      .post("/GetSitesForDropdown.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setSiteList(data1.Data);
          setSiteList1(data1.sites);
        }
        if (data1.status === 0) {
          setSiteList([]);
          setSiteList1([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleOpen2 = () => {
    setOpen2(true);
  };

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      if (pending) {
        const newData = dataList.filter((data) => {
          return data.Present === 0;
        });
        const newData1 = dataList2.filter((data) => {
          return data.Present === 0;
        });
        setpendingDataList(newData);
        setpendingDataList2(newData1);
      } else {
        setpendingDataList(dataList);
        setpendingDataList2(dataList2);
      }
    }
    return () => {
      isSubscribed = false;
    };
  }, [pending]);

  const getLabourList = () => {
    setLabourList([]);
    privateAxios
      .post("/GetLaboursForDropDown.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setLabourList(data1.Data);
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSearchforLabour = () => {
    let date = new Date(selectedMonth);
    let firstDay = moment(
      new Date(date.getFullYear(), date.getMonth(), 1)
    ).format("YYYY-MM-DD");
    let lastDay = moment(
      new Date(date.getFullYear(), date.getMonth() + 1, 0)
    ).format("YYYY-MM-DD");

    if (selectedNameID1 === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter Labour name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    const datatoSend = {
      LabourID: selectedNameID1,
      SDate: firstDay,
      EDate: lastDay,
    };
    console.log(datatoSend);
    privateAxios
      .post("/GetMonthlyDataForAttendance.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setdataList(data1.Data);
          setpendingDataList(data1.Data);
          setAttendance(data1.AttendanceSum);
          setPending(false);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              No records
            </Notification>,
            { placement: "topEnd" }
          );
          setAttendance(0);
        }
      })
      .catch(function (error) {
        console.log(error);
        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            {error}
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  const handleSearchforLabourByDate = () => {
    setProcessing2(true);
    if (!selectedDate1) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select date
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    const datatoSend = { Date: moment(selectedDate1).format("YYYY-MM-DD") };
    privateAxios
      .post("/GetAllLabourForAttendaceByDate.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;
        console.log(data1);

        if (data1.status === 1) {
          data1.Data.forEach((r) => {
            setdataList2(data1.Data);
            setpendingDataList2(data1.Data);
            setPending(false);
          });
        }
        if (data1.status === 0) {
        }
        setProcessing2(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing2(false);
      });
  };

  const ShowMarkAttendance = (
    name,
    LabourID,
    SupID,
    Supervisor,
    Present,
    rate1,
    SiteName,
    InTime = null,
    OutTime = null,
    OverTime = null
  ) => {
    handleOpen();
    getUsersList();
    setLabour(LabourID);
    setText(name);
    setselectedName(Supervisor);
    setselectedNameID(SupID);
    setDesc(Present);
    setRate(rate1);
    setSitename(SiteName);
    setSelectedSites(SiteName ? SiteName.split("-") : []);
    console.log("InTime=>" + InTime);
    if (InTime === null || InTime === 0) setIntime(new Date());
    else setIntime(combineDateTime(selectedDate1, InTime));
    if (OutTime === null || OutTime === 0) setOuttime(new Date());
    else setOuttime(combineDateTime(selectedDate1, OutTime));
    if (OverTime === null || OverTime === 0) setOvertime(null);
    else setOvertime(OverTime);
  };

  const ShowMarkAttendance1 = (
    SupID,
    Supervisor,
    Present,
    rate1,
    dt,
    SiteName,
    InTime = null,
    OutTime = null,
    OverTime = null
  ) => {
    handleOpen1();
    getUsersList();
    setsup(Supervisor);
    setsupID(SupID);
    setDesc(Present);
    setRate(rate1);
    setselectedDate(dt);
    setSitename(SiteName);
    setSelectedSites(SiteName ? SiteName.split("-") : []);
    if (InTime !== null || InTime === 0) setIntime(new Date());
    else setIntime(combineDateTime(dt, InTime));
    if (outTime !== null || OutTime === 0) setOuttime(new Date());
    else setOuttime(combineDateTime(dt, outTime));
    setOuttime(combineDateTime(dt, OutTime));
    if (OverTime !== null || OverTime === 0) setOvertime(null);
    else setOvertime(OverTime);
  };

  const getUsersList = () => {
    setUsersList([]);
    privateAxios
      .post("/GetUsersForWebApp.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          //setDataList(data1.Data)
          data1.Data.forEach((r) => {
            setUsersList((a) => {
              return [...a, { id: r.UniqueID, value: r.Name, label: r.Name }];
            });
          });
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const Mark = () => {
    //AdminID
    if (!selectedName) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Supervisor
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!selectedNameID) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Supervisor
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!labour) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Labour
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!desc) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Attendance
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!rate) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          No Per Day rate
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }
    const datatoSend = {
      ID: labour,
      Date: moment(selectedDate1).format("YYYY-MM-DD"),
      Site: selectedSites.join("-"),
      IN: moment(inTime).format("HH:mm:ss"),
      OUT: moment(outTime).format("HH:mm:ss"),
      OT: overTime,
      Attendance: desc,
    };
    console.log(datatoSend);
    /* 
const datatoSend = {
  ID: selectedNameID1,
      Date: moment(selectedDate).format("YYYY-MM-DD"),
      Sitename: sitename,
      IN: moment(inTime).format("HH:mm:ss"),
      OUT: moment(outTime).format("HH:mm:ss"),
      OT: overTime,
      Site: sitename,
      Attendance: desc,
    };
*/

    privateAxios
      .post("/MarkAttendance.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;
        // console.log(data1);

        if (data1.status === 1) {
          /*           sendFCMPushNotification(
            "Attendance Marked",
            `Labour ID: ${datatoSend.LabourID},Date:${
              datatoSend.Date
            }, Attendance : ${datatoSend.Attendance}, Site : ${
              datatoSend.Sitename
            }, Rate : ${datatoSend.Rate}, PayAmount : ${
              datatoSend.Attendance * datatoSend.Rate +
              datatoSend.Attendance * 10
            }, Marked By : WebApp`,
            "Admin"
          ); */
          handleClose();
          toaster.push(
            <Notification type="success" header="Success" duration={1000}>
              Successfully updated
            </Notification>,
            { placement: "topEnd" }
          );
          handleSearchforLabourByDate();
        }
        if (data1.status === 0) {
        }
        setProcessing2(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing2(false);
      });
    handleClose1();
  };

  const Mark1 = () => {
    //AdminID
    if (!selectedName1) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Labour
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!selectedDate) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Date
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!desc) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Attendance
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!rate) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          No Per Day rate
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }
    console.log(selectedSites);
    const datatoSend = {
      Date: moment(selectedDate).format("YYYY-MM-DD"),

      ID: selectedNameID1,
      IN: moment(inTime).format("HH:mm:ss"),
      OUT: moment(outTime).format("HH:mm:ss"),
      OT: overTime,
      Site: selectedSites.join("-"),
      Attendance: desc,
    };
    console.log(datatoSend);
    privateAxios
      .post("/MarkAttendance.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;
        // console.log(data1);

        if (data1.status === 1) {
          /* sendFCMPushNotification(
            "Attendance Marked",
            `Labour ID: ${datatoSend.LabourID},Date:${
              datatoSend.Date
            }, Attendance : ${datatoSend.Attendance}, Site : ${
              datatoSend.Sitename
            }, Rate : ${datatoSend.Rate}, PayAmount : ${
              datatoSend.Attendance * datatoSend.Rate +
              datatoSend.Attendance * 10
            }, Marked By : WebApp`,
            "Admin"
          ); */
          handleClose();
          toaster.push(
            <Notification type="success" header="Success" duration={1000}>
              Successfully updated
            </Notification>,
            { placement: "topEnd" }
          );
          handleSearchforLabour();
        }
        if (data1.status === 0) {
        }
        setProcessing2(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing2(false);
      });
    handleClose1();
  };

  const handleSearchforLabourBySupervisor = () => {
    setProcessing2(true);
    if (!selectedDate1) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select date
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    const datatoSend = {
      Date: moment(selectedDate1).format("YYYY-MM-DD"),
      Supervisor: selectedSupervisorID,
    };
    privateAxios
      .post("/GetAllLabourForAttendanceBySupervisor.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;
        // console.log(data1);

        if (data1.status === 1) {
          data1.Data.forEach((r) => {
            setdataList2(data1.Data);
            setpendingDataList2(data1.Data);
            setPending(false);
          });
        }
        if (data1.status === 0) {
        }
        setProcessing2(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing2(false);
      });
  };
  const Mark2 = () => {
    //AdminID
    if (!selectedName) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Supervisor
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!selectedNameID) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Supervisor
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!labour) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Labour
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!desc) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Attendance
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!rate) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          No Per Day rate
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }
    const datatoSend = {
      LabourID: labour,
      Attendance: desc,
      AddedBy: AdminID,
      Rate: rate,
      Supervisor: selectedNameID,
      Date: moment(selectedDate1).format("YYYY-MM-DD"),
      Sitename: selectedSites.join("-"),
    };
    console.log(datatoSend);
    privateAxios
      .post("/MarkAttendanceWeb.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;
        // console.log(data1);

        if (data1.status === 1) {
          handleClose();
          toaster.push(
            <Notification type="success" header="Success" duration={1000}>
              Successfully updated
            </Notification>,
            { placement: "topEnd" }
          );
          handleSearchforLabourBySupervisor();
        }
        if (data1.status === 0) {
        }
        setProcessing2(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing2(false);
      });
    handleClose2();
  };
  const ShowMarkAttendance2 = (
    name,
    LabourID,
    SupID,
    Supervisor,
    Present,
    rate1,
    SiteName
  ) => {
    handleOpen2();
    getUsersList();
    setLabour(LabourID);
    setText(name);
    setselectedName(Supervisor);
    setselectedNameID(SupID);
    setDesc(Present);
    setRate(rate1);
    setSitename(SiteName);
    setSelectedSites(SiteName ? SiteName.split("-") : []);
  };
  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>Manage Attendance</h4>
      </Header>
      <div className="InputContainer">
        <Form.Group controlId="radioList">
          <RadioGroup
            name="radioList"
            inline
            appearance="picker"
            defaultValue="A"
            onChange={(e) => {
              setRadio(e);
              setPending(false);
            }}
            value={radio}
          >
            <Radio value="A">Labour wise</Radio>
            <Radio value="D">Date wise</Radio>
            {/* <Radio value="C">Supervisor wise</Radio> */}
          </RadioGroup>
          &nbsp;
          <Table2XLSX />
          &nbsp;
          <Toggle
            checked={pending}
            onChange={(val) => {
              if (val) {
                const newData = dataList.filter((data) => {
                  return data.Present === 0;
                });
                const newData1 = dataList2.filter((data) => {
                  return data.Present === 0;
                });
                setpendingDataList(newData);
                setpendingDataList2(newData1);
              } else {
                setpendingDataList(dataList);
                setpendingDataList2(dataList2);
              }
              setPending(val);
            }}
            size="lg"
            checkedChildren="Pending"
            unCheckedChildren="All"
          />
          <hr />
        </Form.Group>
        <Form layout="inline">
          {radio === "A" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ flex: 1 }}>
                  <Stack spacing={6}>
                    <label>Month:</label>
                    <DatePicker
                      oneTap
                      cleanable={false}
                      format="MMM-yyyy"
                      limitStartYear={2023}
                      defaultValue={lastDay}
                      ranges={[]}
                      onSelect={(e) => setSelectedMonth(e)}
                      style={{ width: 120 }}
                    />

                    <label>Employee Name:</label>
                    <SelectPicker
                      cleanable={false}
                      data={LabourList}
                      appearance="default"
                      placeholder="Select Employee"
                      style={{ width: 224 }}
                      value={selectedName1}
                      onSelect={(e, Item) => {
                        setselectedName1(e);
                        console.log(Item);
                        setselectedNameID1(Item.key);
                      }}
                      onChange={() => setdataList([])}
                    />

                    <IconButton
                      onClick={handleSearchforLabour}
                      loading={processing}
                      icon={<SearchIcon />}
                    >
                      Refresh
                    </IconButton>
                  </Stack>
                </div>
                <div style={{ justifyContent: "center" }}>
                  <div>
                    <div> Attendance : {attendance}</div>
                    &nbsp;
                  </div>
                </div>
              </div>
            </>
          )}

          {radio === "D" && (
            <Stack spacing={4}>
              <label>Date:</label>

              <DatePicker
                cleanable={false}
                appearance="default"
                placeholder="Select Date"
                oneTap
                format="dd-MM-yyyy"
                style={{ width: 150 }}
                value={selectedDate1}
                onChange={(e) => {
                  console.log(e);
                  setdataList2([]);
                  setselectedDate1(e);
                }}
              />

              <IconButton
                onClick={handleSearchforLabourByDate}
                loading={processing2}
                icon={<SearchIcon />}
              >
                Refresh
              </IconButton>
            </Stack>
          )}

          {radio === "C" && (
            <Stack spacing={4}>
              <label>Date:</label>
              <DatePicker
                format="dd-MM-yyyy"
                appearance="default"
                cleanable={false}
                placeholder="Select Date"
                oneTap
                style={{ width: 150 }}
                value={selectedDate1}
                onChange={(e) => {
                  console.log(e);
                  setdataList2([]);
                  setselectedDate1(e);
                }}
              />

              <label>Supervisor:</label>
              <SelectPicker
                cleanable={false}
                data={usersList}
                appearance="default"
                placeholder="Select Supervisor"
                style={{ width: 200 }}
                key="id"
                sort={() => {
                  return (a, b) => {
                    return compare(a.value, b.value);
                  };
                }}
                onSelect={(e, Item) => {
                  setselectedSupervisor(e);
                  setselectedSupervisorID(Item.id);
                }}
                value={selectedSupervisor}
              />
              <IconButton
                onClick={handleSearchforLabourBySupervisor}
                loading={processing2}
                icon={<SearchIcon />}
              >
                Refresh
              </IconButton>
            </Stack>
          )}
        </Form>

        <div>
          <Modal open={open1} onClose={handleClose1} size="xs">
            <Modal.Header>
              <Modal.Title>{`Mark Attendance for ${moment(selectedDate).format(
                "Do-MMM-YYYY"
              )}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form fluid>
                <Form.Group>
                  <Form.ControlLabel>Name</Form.ControlLabel>
                  <Form.Control
                    name="name"
                    value={selectedName1}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>SiteName</Form.ControlLabel>
                  {/* <SelectPicker
                    data={siteList}
                    cleanable={false}
                    appearance="default"
                    placeholder="Select Site"
                    style={{ width: 200 }}
                    key="id"
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      // console.log(Item);
                      setSitename(e);
                    }}
                    value={sitename}
                  /> */}
                  <TagPicker
                    data={siteList}
                    onChange={setSelectedSites}
                    value={selectedSites}
                    block
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Attendance</Form.ControlLabel>
                  <SelectPicker
                    data={Attendance}
                    cleanable={false}
                    appearance="default"
                    placeholder="Select"
                    defaultValue={desc}
                    style={{ width: 220 }}
                    value={desc}
                    onChange={(e) => {
                      setDesc(e);
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>InTime</Form.ControlLabel>
                  <DatePicker
                    format="HH:mm:ss"
                    caretAs={FaClock}
                    ranges={[]}
                    cleanable={false}
                    defaultValue={new Date(inTime)}
                    value={inTime}
                    onChange={(value, event) => {
                      setIntime(value);
                    }}
                    shouldDisableHour={(hour) => hour < 7 || hour > 23}
                    shouldDisableMinute={(minute) => minute % 15 !== 0}
                    shouldDisableSecond={(second) => second !== 0}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>OutTime</Form.ControlLabel>
                  <DatePicker
                    cleanable={false}
                    format="HH:mm:ss"
                    caretAs={FaClock}
                    ranges={[]}
                    defaultValue={new Date(outTime)}
                    value={outTime}
                    onChange={(value, event) => {
                      setOuttime(value);
                    }}
                    shouldDisableHour={(hour) => hour < 7 || hour > 23}
                    shouldDisableMinute={(minute) => minute % 15 !== 0}
                    shouldDisableSecond={(second) => second !== 0}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Overtime</Form.ControlLabel>
                  <SelectPicker
                    cleanable={false}
                    searchable={false}
                    data={timeData}
                    appearance="default"
                    placeholder="Select"
                    defaultValue={overTime}
                    style={{ width: 220 }}
                    value={overTime}
                    onChange={(e) => {
                      setOvertime(e);
                    }}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              {/* <Button onClick={Mark1} appearance="primary">
                Submit
              </Button> */}
              <CalmButton title="Submit" onClick={Mark1} />
              <Button onClick={handleClose1} appearance="subtle">
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <div>
          <Modal open={open} onClose={handleClose} size="xs">
            <Modal.Header>
              <Modal.Title>{`Mark Attendance for ${moment(selectedDate1).format(
                "Do-MMM-YYYY"
              )}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form fluid>
                <Form.Group>
                  <Form.ControlLabel>Name</Form.ControlLabel>
                  <Form.Control
                    name="name"
                    value={text}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                  />
                  <Form.HelpText>Required</Form.HelpText>
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Supervisor</Form.ControlLabel>
                  <SelectPicker
                    data={usersList}
                    cleanable={false}
                    appearance="default"
                    placeholder="Default"
                    defaultValue={selectedName}
                    style={{ width: 200 }}
                    key="id"
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      console.log(Item);
                      setselectedName(e);
                      setselectedNameID(Item.id);
                    }}
                    value={selectedName}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>SiteName</Form.ControlLabel>
                  {/*  <SelectPicker
                    data={siteList}
                    cleanable={false}
                    appearance="default"
                    placeholder="Select Site"
                    style={{ width: 200 }}
                    key="id"
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      // console.log(Item);
                      setSitename(e);
                    }}
                    value={sitename}
                  /> */}
                  <TagPicker
                    data={siteList}
                    onChange={setSelectedSites}
                    value={selectedSites}
                    block
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Attendance</Form.ControlLabel>
                  <SelectPicker
                    cleanable={false}
                    data={Attendance}
                    appearance="default"
                    placeholder="Select"
                    defaultValue={desc}
                    style={{ width: 220 }}
                    value={desc}
                    onChange={(e) => {
                      setDesc(e);
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>InTime</Form.ControlLabel>
                  <DatePicker
                    format="HH:mm:ss"
                    caretAs={FaClock}
                    ranges={[]}
                    cleanable={false}
                    defaultValue={new Date(inTime)}
                    value={inTime}
                    onChange={(value, event) => {
                      setIntime(value);
                    }}
                    shouldDisableHour={(hour) => hour < 7 || hour > 23}
                    shouldDisableMinute={(minute) => minute % 15 !== 0}
                    shouldDisableSecond={(second) => second !== 0}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>OutTime</Form.ControlLabel>
                  <DatePicker
                    cleanable={false}
                    format="HH:mm:ss"
                    caretAs={FaClock}
                    ranges={[]}
                    defaultValue={new Date(outTime)}
                    value={outTime}
                    onChange={(value, event) => {
                      setOuttime(value);
                    }}
                    shouldDisableHour={(hour) => hour < 7 || hour > 23}
                    shouldDisableMinute={(minute) => minute % 15 !== 0}
                    shouldDisableSecond={(second) => second !== 0}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Overtime</Form.ControlLabel>
                  <SelectPicker
                    cleanable={false}
                    searchable={false}
                    data={timeData}
                    appearance="default"
                    placeholder="Select"
                    defaultValue={overTime}
                    style={{ width: 220 }}
                    value={overTime}
                    onChange={(e) => {
                      setOvertime(e);
                    }}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <CalmButton title="Submit" onClick={Mark} />
              <Button onClick={handleClose} appearance="subtle">
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <div>
          <Modal open={open2} onClose={handleClose2} size="xs">
            <Modal.Header>
              <Modal.Title>{`Mark Attendance for ${moment(selectedDate1).format(
                "Do-MMM-YYYY"
              )}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form fluid>
                <Form.Group>
                  <Form.ControlLabel>Name</Form.ControlLabel>
                  <Form.Control
                    name="name"
                    value={text}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                  />
                  <Form.HelpText>Required</Form.HelpText>
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Supervisor</Form.ControlLabel>
                  <SelectPicker
                    data={usersList}
                    cleanable={false}
                    appearance="default"
                    placeholder="Default"
                    defaultValue={selectedSupervisorID}
                    style={{ width: 200 }}
                    key="id"
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      console.log(Item);
                      setselectedName(e);
                      setselectedNameID(Item.id);
                    }}
                    value={selectedName}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>SiteName</Form.ControlLabel>
                  {/*                   <SelectPicker
                    data={siteList}
                    cleanable={false}
                    appearance="default"
                    placeholder="Select Site"
                    style={{ width: 200 }}
                    key="id"
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      // console.log(Item);
                      setSitename(e);
                    }}
                    value={sitename}
                  /> */}
                  <TagPicker
                    data={siteList}
                    onChange={setSelectedSites}
                    value={selectedSites}
                    block
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Attendance</Form.ControlLabel>
                  <SelectPicker
                    data={Attendance}
                    cleanable={false}
                    appearance="default"
                    placeholder="Select"
                    defaultValue={desc}
                    style={{ width: 220 }}
                    value={desc}
                    onChange={(e) => {
                      setDesc(e);
                    }}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={Mark2} appearance="primary">
                Submit
              </Button>
              <Button onClick={handleClose2} appearance="subtle">
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      <div
        className="InputContainer"
        style={{ overflow: "auto", maxHeight: 500 }}
      >
        {radio === "A" && (
          <Table
            striped
            bordered
            hover
            id="TableData"
            style={{ position: "relative", maxHeight: 550 }}
          >
            <thead>
              <tr>
                {column.map((a) => (
                  <th
                    key={a.field}
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "grey",
                    }}
                  >
                    {a.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {pendingDataList.map((a) => (
                <tr key={a.Date}>
                  <td>{a.Date}</td>
                  <td>{selectedName1}</td>
                  <td>
                    {" "}
                    <Button
                      style={{ marginHorizontal: 10, fontSize: 15 }}
                      color={`${a.Present > 0 ? "green" : "red"}`}
                      appearance="link"
                      onClick={() =>
                        ShowMarkAttendance1(
                          a.SupID,
                          a.Supervisor,
                          a.Present,
                          a.Rate,
                          a.Date,
                          a.Sitename,
                          a.InTime,
                          a.OutTime,
                          a.Overtime
                        )
                      }
                      loading={processing1}
                    >
                      {a.Present === 0
                        ? "Absent"
                        : a.Present === 0.5
                        ? "HalfDay"
                        : "Present"}
                    </Button>
                  </td>

                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Rate)}
                  </td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Salary)}
                  </td>
                  <td>{a.InTime === 0 ? "" : a.InTime}</td>
                  <td>{a.OutTime === 0 ? "" : a.OutTime}</td>
                  <td>{a.Overtime === 0 ? "" : a.Overtime + " Hrs"}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Total)}
                  </td>
                  <td>{a.Supervisor}</td>
                  <td>{a.Sitename}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {radio === "D" && (
          <Table
            id="TableData"
            style={{ position: "relative", maxHeight: 550 }}
          >
            <thead>
              <tr>
                {column2.map((a) => (
                  <th
                    key={a.field}
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "grey",
                    }}
                  >
                    {a.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {pendingDataList2.map((a) => (
                <tr key={a.key}>
                  <td>{a.Name}</td>
                  <td>
                    {" "}
                    <Button
                      style={{ marginHorizontal: 10, fontSize: 15 }}
                      color={`${a.Present > 0 ? "green" : "red"}`}
                      appearance="link"
                      onClick={() =>
                        ShowMarkAttendance(
                          a.Name,
                          a.key,
                          a.SupID,
                          a.Supervisor,
                          a.Present,
                          a.Rate,

                          a.Sitename,
                          a.InTime,
                          a.OutTime,
                          a.Overtime
                        )
                      }
                      loading={processing1}
                    >
                      {a.Present === 0
                        ? "Absent"
                        : a.Present === 0.5
                        ? "HalfDay"
                        : "Present"}
                    </Button>
                  </td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Rate)}
                  </td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Salary)}
                  </td>
                  <td>{a.InTime === 0 ? "" : a.InTime}</td>
                  <td>{a.OutTime === 0 ? "" : a.OutTime}</td>
                  <td>{a.Overtime === 0 ? "" : a.Overtime + " Hrs"}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Total)}
                  </td>
                  <td>{a.Supervisor}</td>
                  <td>{a.Sitename}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {radio === "C" && (
          <Table
            id="TableData"
            style={{ position: "relative", maxHeight: 550 }}
          >
            <thead>
              <tr>
                {column2.map((a) => (
                  <th
                    key={a.field}
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "grey",
                    }}
                  >
                    {a.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {pendingDataList2.map((a) => (
                <tr key={a.key}>
                  <td>{a.Name}</td>
                  <td>{a.Supervisor}</td>
                  <td>{a.Sitename}</td>
                  <td>
                    {" "}
                    <Button
                      style={{ marginHorizontal: 10, fontSize: 15 }}
                      color={`${a.Present > 0 ? "green" : "red"}`}
                      appearance="link"
                      onClick={() =>
                        ShowMarkAttendance2(
                          a.Name,
                          a.key,
                          a.SupID,
                          a.Supervisor,
                          a.Present,
                          a.PerDayRate,
                          a.Sitename
                        )
                      }
                      loading={processing1}
                    >
                      {a.Present}
                    </Button>
                  </td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.PerDayRate)}
                  </td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Salary)}
                  </td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Total)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default MarkAttendance;
