import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Main from "./pages/Main";
import "rsuite/dist/rsuite.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import { RequireAuth } from "./functions";
import AddNew from "./pages/AddNew";
import ViewAll from "./pages/ViewAll";
import Dashboard from "./pages/Dashboard";
import ManageUsers from "./pages/ManageUsers";
import Home from "./pages/Home";

import ViewLabours from "./pages/ViewLabours";
import ViewAttendance from "./pages/ViewAttendance";
import GetSalary from "./pages/GetSalary";
import MarkAttendance from "./pages/MarkAttendance";
import ViewAllNew from "./pages/ViewAllNew";
import Summary from "./pages/Summary";
import OtherLabours from "./pages/OtherLabours";
import ManageSites from "./pages/ManageSites";
import OtherLaboursPDF from "./pages/OtherLabourPDF";
import LoginNew from "./pages/LoginNew";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<LoginNew />} />

        <Route path="Home" element={<Home />} />
        <Route
          path="Main"
          element={
            <RequireAuth>
              <Main />
            </RequireAuth>
          }
        >
          <Route path="Dashboard" element={<Dashboard />} />
          <Route path="AddNew" element={<AddNew />} />
          <Route path="ViewAll" element={<ViewAll />} />
          <Route path="ManageUsers" element={<ManageUsers />} />

          <Route path="ViewLabours" element={<ViewLabours />} />
          <Route path="ViewAttendance" element={<ViewAttendance />} />
          <Route path="GetSalary" element={<GetSalary />} />
          <Route path="GetSalary/:Site" element={<GetSalary />} />
          <Route path="ViewAttendance/:Emp" element={<ViewAttendance />} />
          <Route path="MarkAttendance/:Emp" element={<MarkAttendance />} />
          <Route path="MarkAttendance" element={<MarkAttendance />} />
          <Route path="ViewAllNew" element={<ViewAllNew />} />
          <Route path="Summary" element={<Summary />} />
          <Route path="OtherLabours" element={<OtherLabours />} />
          <Route path="GeneratePDF" element={<OtherLaboursPDF />} />
          <Route path="ManageSites" element={<ManageSites />} />
          <Route path="*" component={<Login />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
