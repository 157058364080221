import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { app } from "../Config";
import axios from "axios";
import { useDispatch } from "react-redux";
import { updateUser } from "./redux/actions";

import { useToaster, Notification } from "rsuite";
import { LOGGED_IN, SET_USER } from "./redux/type";
import { OS, currentBrowser } from "../functions";
const LoginForm = () => {
  return (
    <div id="loginform">
      <FormHeader title="Login" />
      <Form />
    </div>
  );
};

const FormHeader = (props) => <h2 id="headerTitle">{props.title}</h2>;

const Form = () => {
  const toaster = useToaster();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");

  const [ip, setIP] = useState("");
  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    console.log(res.data);
    setIP(res.data.ip);
  };

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) getData();
    return () => {
      isSubscribed = false;
    };
  }, []);

  function handleLogin(e) {
    e.preventDefault();
    const OperatingSystem = OS(window);
    const browser = currentBrowser(window);
    console.log(OperatingSystem);
    console.log(browser);
    axios
      .post("Login2.php", {
        Email: user,
        Password: password,
        IP: ip,
        OS: OperatingSystem,
        Browser: browser,
      })
      .then((response) => {
        const result = response.data;
        console.log(result.Data);
        if (result.Data.Type !== "Admin" && result.Data.Type !== "Office") {
          console.log("Only Admins allowed");
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              Only Admins allowed. Access denied
            </Notification>,
            { placement: "topEnd" }
          );
        } else {
          toaster.push(
            <Notification type="success" header="Success" duration={1000}>
              Access granted
            </Notification>,
            { placement: "topEnd" }
          );
          localStorage.setItem("accessToken", result.Data.SessionID);

          dispatch({
            type: SET_USER,
            payload: {
              Name: result.Data.Name,
              Email: result.Data.Email,
              UniqueID: result.Data.UniqueID,
              userDetails: result.Data,
            },
          });
          navigate("/Main");
        }
      });
  }

  return (
    <div>
      <form onSubmit={handleLogin}>
        <FormInput
          description="Username"
          placeholder="Enter your username"
          type="text"
          value={user}
          onEnter={(e) => setUser(e)}
        />
        <FormInput
          description="Password"
          placeholder="Enter your password"
          type="password"
          value={password}
          onEnter={(e) => setPassword(e)}
        />
      </form>
      <FormButton Click={handleLogin} title="Log in" />
    </div>
  );
};

const FormButton = (props) => {
  return (
    <div id="button" className="row">
      <button onClick={props.Click}>{props.title}</button>
    </div>
  );
};

const FormInput = ({ description, type, placeholder, value, onEnter }) => {
  function onType(text) {
    onEnter(text);
  }
  return (
    <div className="row">
      <label>{description}</label>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onType(e.target.value)}
      />
    </div>
  );
};

export default LoginForm;
