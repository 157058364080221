import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Input,
  Form,
  Modal,
  useToaster,
  Notification,
  DatePicker,
  Tooltip,
  Whisper,
  IconButton,
  SelectPicker,
  InputGroup,
  Stack,
  Toggle,
} from "rsuite";
import EditIcon from "@rsuite/icons/Edit";
import Header from "../component/Header";
import Table from "react-bootstrap/Table";
import ReloadIcon from "@rsuite/icons/Reload";

import VisibleIcon from "@rsuite/icons/Visible";
import SendIcon from "@rsuite/icons/Send";
import EyeCloseIcon from "@rsuite/icons/EyeClose";
import moment from "moment";
import { Table2XLSX, validateNumber } from "../functions";
import { DocumentScanner, PersonAdd } from "@mui/icons-material";
import privateAxios from "../Axios/axiosConfig";
import axios from "axios";
import { Link } from "react-router-dom";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));
// TODO:Display pic on labour name click
const ViewLabours = () => {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [oldRate, setOldRate] = React.useState(0);
  const [processing, setProcessing] = useState(false);
  const [processing1, setProcessing1] = useState(false);
  const [checked, setChecked] = useState(false);
  const [size, setSize] = useState("md");
  const [labourList, setLabourList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [selectedName, setselectedName] = useState("");
  const [selectedNameID, setselectedNameID] = useState("");
  const [billCopy, setBillCopy] = useState("");
  const [docsCopy, setDocsCopy] = useState("");

  const [updateLabour, setUpdateLabour] = useState({
    ID: "",
    Name: "",
    Desc: "",
    PerDayRate: "",
    EffectiveFrom: new Date(),
    Type: "",
    Supervisor: "",
    SupervisorID: "",
    Mobile: "",
  });
  const column = useMemo(
    () => [
      { field: "SrNo", title: "ID" },
      { field: "Name", title: "Name" },
      { field: "Type", title: "Type" },
      { field: "PerDayRate", title: "PerDayRate" },
      { field: "Additional", title: "Additional" },
      { field: "Supervisor", title: "Supervisor" },
      { field: "Mobile", title: "Mobile" },
    ],
    []
  );
  const [value, setValue] = React.useState({
    Name: "",
    Type: "",
    PerDayRate: 0,
    OTRate: 0,
    Additional: "",
    Mobile: "",
  });
  const toaster = useToaster();
  function compare(a, b) {
    let nameA = a.toUpperCase();
    let nameB = b.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) handleRefresh();
    return () => {
      isSubscribed = false;
    };
  }, []);

  const getUsersList = () => {
    setUsersList([]);
    privateAxios
      .post("GetUsersForWebApp.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          //setDataList(data1.Data)
          data1.Data.forEach((r) => {
            setUsersList((a) => {
              return [...a, { id: r.UniqueID, value: r.Name, label: r.Name }];
            });
          });
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleRefresh = () => {
    setProcessing1(true);
    getUsersList();
    privateAxios
      .post("GetAllLaboursForWebApp.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setLabourList(data1.Data);
          setCount(data1.Count);
          var outputData = labourList.map(Object.values);
          setData(outputData);
        }
        if (data1.status === 0) {
        }
        setProcessing1(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing1(false);
      });
  };

  const SaveData = async () => {
    setProcessing(true);

    if (value.Name === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Enter Labour Name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (value.Type === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Enter Type
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (value.PerDayRate === "" || value.PerDayRate === 0) {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Enter Per Day Rate
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (selectedNameID === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Please select supervisor
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (checked && billCopy === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select document copy
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    try {
      if (checked) {
        setProcessing1(true);
        const formData = new FormData();
        formData.append("File", billCopy);
        axios
          .post("https://Downloads.ashaelectricals.in/AddBill.php", formData)
          .then((response) => {
            const result = response.data;
            setProcessing1(false);
            if (result.status === 1) {
              setProcessing(true);
              const datatoSend = {
                Name: value.Name,
                Type: value.Type,
                Note: value.Additional,
                OTRate: value.OTRate,
                Rate: value.PerDayRate,
                Mobile: value.Mobile,
                Supervisor: selectedNameID,
                Doc: result.Filename,
              };
              privateAxios
                .post("AddLabour.php", datatoSend)
                .then((response) => {
                  const result = response.data;
                  console.log(result.status);
                  if (result.status === 2) {
                    toaster.push(
                      <Notification type="Error" header="Error" duration={1000}>
                        `${value.Name} already exists.`
                      </Notification>,
                      { placement: "topEnd" }
                    );
                    setProcessing(false);
                  }
                  if (result.status === 0) {
                    toaster.push(
                      <Notification
                        type="success"
                        header="success"
                        duration={1000}
                      >
                        Saved Successfully
                      </Notification>,
                      { placement: "topEnd" }
                    );
                    setProcessing(false);
                  }
                  if (result.status === 1) {
                    /* sendFCMPushNotification(
                "Added in Labour",
                `Name : ${datatoSend.Name},Type:${datatoSend.Type}, Rate : ${datatoSend.Rate}, Mobile : ${datatoSend.Mobile}, Added By : WebApp`,
                "Admin"
              ); */
                    toaster.push(
                      <Notification
                        type="success"
                        header="success"
                        duration={1000}
                      >
                        Saved Successfully
                      </Notification>,
                      { placement: "topEnd" }
                    );
                    setProcessing(false);
                  }
                })
                .catch(function (error) {
                  console.log(error);
                  setProcessing(false);
                });
              setProcessing(false);
            }
            if (result.status === 0) {
              toaster.push(
                <Notification type="error" header="Error" duration={1000}>
                  {`Error occured - ${result.Error}`}
                </Notification>,
                { placement: "topEnd" }
              );
              setProcessing1(false);
            }
          })
          .catch(function (error) {
            console.log(error);
            setProcessing1(false);
          });
      } else {
        setProcessing(true);
        const datatoSend = {
          Name: value.Name,
          Type: value.Type,
          Note: value.Additional,
          OTRate: value.OTRate,
          Rate: value.PerDayRate,
          Mobile: value.Mobile,
          Supervisor: selectedNameID,
        };
        privateAxios
          .post("AddLabour.php", datatoSend, {})
          .then((response) => {
            const result = response.data;
            console.log(result.status);
            if (result.status === 2) {
              toaster.push(
                <Notification type="Error" header="Error" duration={1000}>
                  `${value.Name} already exists.`
                </Notification>,
                { placement: "topEnd" }
              );
              setProcessing(false);
            }
            if (result.status === 0) {
              toaster.push(
                <Notification type="success" header="success" duration={1000}>
                  Saved Successfully
                </Notification>,
                { placement: "topEnd" }
              );
              setProcessing(false);
            }
            if (result.status === 1) {
              /* sendFCMPushNotification(
                "Added in Labour",
                `Name : ${datatoSend.Name},Type:${datatoSend.Type}, Rate : ${datatoSend.Rate}, Mobile : ${datatoSend.Mobile}, Added By : WebApp`,
                "Admin"
              ); */
              toaster.push(
                <Notification type="success" header="success" duration={1000}>
                  Saved Successfully
                </Notification>,
                { placement: "topEnd" }
              );
              setProcessing(false);
            }
          })
          .catch(function (error) {
            console.log(error);
            setProcessing(false);
          });
        setProcessing(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setProcessing(false);
      setProcessing1(false);
    }
  };

  function handleEdit(data) {
    handleOpen();
    console.log(data);
    setUpdateLabour((a) => {
      return {
        ...a,
        ID: data.SrNo,
        Name: data.Name,
        Note: data.Note,
        Rate: data.Rate,
        Designation: data.Designation,
        Supervisor: data.Supervisor,
        SupervisorID: data.SupervisorID,
        Mobile: data.Mobile,
      };
    });

    setOldRate(data.Rate);
  }

  function saveUpdated() {
    if (updateLabour.Name === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Enter Employee Name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (updateLabour.Designation === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Enter Employee Type
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (updateLabour.Rate === "" || updateLabour.Rate === 0) {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Enter Per Day Rate
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (updateLabour.Supervisor === "" || updateLabour.SupervisorID === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Please select supervisor
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (updateLabour.Mobile !== "" && !validateNumber(updateLabour.Mobile)) {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Please enter valid 10 digit mobile no.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (
      oldRate !== parseInt(updateLabour.PerDayRate) &&
      updateLabour.EffectiveFrom === ""
    ) {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Please select effective from date to update new per day rate.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    let datatoSend = {};

    if (oldRate !== parseInt(updateLabour.PerDayRate)) {
      datatoSend = {
        ID: updateLabour.ID,
        Name: updateLabour.Name,
        Note: updateLabour.Note,
        Rate: updateLabour.Rate,
        OTRate: updateLabour.OTRate,
        Designation: updateLabour.Designation,
        Supervisor: updateLabour.Supervisor,
        SupervisorID: updateLabour.SupervisorID,
        Mobile: updateLabour.Mobile,
        NewRate: true,
        EffectiveFrom: moment(updateLabour.EffectiveFrom).format("YYYY-MM-DD"),
      };
    } else {
      datatoSend = {
        ID: data.SrNo,
        Name: data.Name,
        Note: data.Note,
        Rate: data.Rate,
        OTRate: data.OTRate,
        Designation: data.Designation,
        Supervisor: data.Supervisor,
        SupervisorID: data.SupervisorID,
        Mobile: updateLabour.Mobile,
        NewRate: false,
      };
    }

    if (docsCopy) {
      setProcessing1(true);
      const formData = new FormData();
      formData.append("File", docsCopy);
      axios
        .post("https://Downloads.ashaelectricals.in/AddBill.php", formData)
        .then((response) => {
          const result = response.data;
          setProcessing1(false);
          if (result.status === 1) {
            privateAxios
              .post("UpdateLabourForWebDocs.php", {
                ...datatoSend,
                Doc: result.Filename,
              })
              .then((response) => {
                const result = response.data;
                //console.log(result.status);
                if (result.status === 1) {
                  toaster.push(
                    <Notification
                      type="success"
                      header="success"
                      duration={1000}
                    >
                      Saved Successfully
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  setProcessing(false);
                  handleClose();
                }
                if (result.status === 2) {
                  toaster.push(
                    <Notification
                      type="success"
                      header="success"
                      duration={1000}
                    >
                      Updated Successfully
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  setProcessing(false);
                  handleClose();
                }
                if (result.status === 0) {
                  toaster.push(
                    <Notification type="error" header="Error" duration={1000}>
                      Error occured
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  setProcessing(false);
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          }
          if (result.status === 0) {
            toaster.push(
              <Notification type="error" header="Error" duration={1000}>
                {`Error occured - ${result.Error}`}
              </Notification>,
              { placement: "topEnd" }
            );
            setProcessing1(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          setProcessing1(false);
        });
    } else {
      privateAxios
        .post("UpdateLabourForWeb.php", datatoSend)
        .then((response) => {
          const result = response.data;
          //console.log(result.status);
          if (result.status === 1) {
            toaster.push(
              <Notification type="success" header="success" duration={1000}>
                Saved Successfully
              </Notification>,
              { placement: "topEnd" }
            );
            setProcessing(false);
            handleClose();
          }
          if (result.status === 2) {
            toaster.push(
              <Notification type="success" header="success" duration={1000}>
                Updated Successfully
              </Notification>,
              { placement: "topEnd" }
            );
            setProcessing(false);
            handleClose();
          }
          if (result.status === 0) {
            toaster.push(
              <Notification type="error" header="Error" duration={1000}>
                Error occured
              </Notification>,
              { placement: "topEnd" }
            );
            setProcessing(false);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function handleStatus(ID, Status) {
    privateAxios
      .post("ChangeStatus.php", {
        LabourID: ID,
        Status,
      })
      .then((response) => {
        const result = response.data;
        if (result.status === 1) {
          toaster.push(
            <Notification type="success" header="success" duration={1000}>
              Updated Successfully
            </Notification>,
            { placement: "topEnd" }
          );
          handleRefresh();
          setProcessing(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>Manage Employee</h4>
      </Header>
      <div className="Subbody">
        <div style={{ flexDirection: "column" }}>
          <Stack spacing={10} style={{ marginBottom: 15 }}>
            <Whisper trigger="focus" speaker={<Tooltip>Required</Tooltip>}>
              <Input
                style={{ width: 200 }}
                placeholder="Enter Employee name"
                value={value.Name}
                onChange={(e) =>
                  setValue((a) => {
                    return { ...a, Name: e };
                  })
                }
              />
            </Whisper>
            <Input
              style={{ width: 200 }}
              placeholder="Enter Employee Type"
              value={value.Type}
              onChange={(e) =>
                setValue((a) => {
                  return { ...a, Type: e };
                })
              }
            />
            <InputGroup style={{ width: "240px" }}>
              <InputGroup.Addon>Per Day (₹)</InputGroup.Addon>
              <Input
                value={value.PerDayRate}
                onChange={(e) => {
                  setValue((a) => {
                    return { ...a, PerDayRate: e };
                  });
                }}
              />
            </InputGroup>
            <InputGroup style={{ width: "240px" }}>
              <InputGroup.Addon>OT (₹)</InputGroup.Addon>
              <Input
                value={value.OTRate}
                onChange={(e) => {
                  setValue((a) => {
                    return { ...a, OTRate: e };
                  });
                }}
              />
            </InputGroup>
            <Input
              name="textarea-7"
              placeholder="Mobile"
              rows={1}
              accepter={Textarea}
              value={value.Mobile}
              style={{ resize: "none", width: 200 }}
              onChange={(e) =>
                setValue((a) => {
                  return { ...a, Mobile: e };
                })
              }
            />
          </Stack>

          <Stack spacing={10}>
            <SelectPicker
              cleanable={false}
              data={usersList}
              appearance="default"
              searchable={false}
              placeholder="Select Supervisor"
              style={{ width: 200 }}
              key="id"
              sort={() => {
                return (a, b) => {
                  return compare(a.value, b.value);
                };
              }}
              onSelect={(e, Item) => {
                console.log(Item);
                setselectedName(e);
                setselectedNameID(Item.id);
              }}
              value={selectedName}
            />
            <Toggle
              checked={checked}
              checkedChildren="Doc"
              unCheckedChildren="No Doc"
              onChange={(e) => {
                setChecked(e);
                console.log(e);
              }}
              size={size}
            />
            {checked && (
              <input
                type="file"
                name="file"
                onChange={(e) => setBillCopy(e.target.files[0])}
              ></input>
            )}
            <Button
              color="green"
              appearance="primary"
              onClick={SaveData}
              loading={processing}
            >
              <PersonAdd /> Add
            </Button>
            <Button
              color="green"
              appearance="primary"
              onClick={handleRefresh}
              loading={processing1}
            >
              <ReloadIcon style={{ margin: "5px" }} /> Refresh
            </Button>
            <Table2XLSX />
          </Stack>
        </div>
      </div>
      <Header>
        <h5
          style={{ color: "white", fontSize: 20 }}
        >{`Employee (${count})`}</h5>
      </Header>
      <div
        className="InputContainer"
        style={{ overflow: "auto", maxHeight: 500 }}
      >
        <Table id="TableData" style={{ position: "relative", maxHeight: 550 }}>
          <thead>
            <tr>
              {column.map((a) => (
                <th
                  key={a.field}
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "grey",
                    zIndex: 100,
                  }}
                >
                  {a.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {labourList.map((a) => (
              <tr key={a.SrNo}>
                <td
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  {a.SrNo}
                </td>
                <td
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  <Link to={`/Main/MarkAttendance/${a.SrNo}`}>{a.Name}</Link>
                </td>
                <td style={{ textTransform: "capitalize" }}>{a.Designation}</td>
                <td>{a.Rate}</td>
                <td style={{ textTransform: "capitalize" }}>{a.Note}</td>
                <td style={{ textTransform: "capitalize" }}>{a.Supervisor}</td>
                <td>{a.Mobile}</td>
                <td width={60}>
                  <IconButton
                    icon={<EditIcon />}
                    color="blue"
                    appearance="primary"
                    circle
                    onClick={() => {
                      handleEdit(a);
                    }}
                  />
                </td>
                <td width={60}>
                  <IconButton
                    icon={a.Status === "1" ? <VisibleIcon /> : <EyeCloseIcon />}
                    color={a.Status === "1" ? "green" : "red"}
                    appearance="primary"
                    circle
                    onClick={() => {
                      handleStatus(a.SrNo, a.Status);
                    }}
                  />
                </td>
                {a.Doc && (
                  <td width={60}>
                    <IconButton
                      icon={<DocumentScanner />}
                      color="blue"
                      appearance="primary"
                      circle
                      href={`https://downloads.ashaelectricals.in/upload/${a.Doc}`}
                    />
                  </td>
                )}
                {/*  <td width={60}>
                  <IconButton
                    icon={<SendIcon />}
                    color="primary"
                    appearance="primary"
                    circle
                    href={`https://salary.ashaelectricals.in/Details/${a.UniqueID}`}
                  />
                </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Info: ===================================================== */}

      <div>
        <Modal open={open} onClose={handleClose} size="xs">
          <Modal.Header>
            <Modal.Title style={{ textAlign: "center" }}>
              <label
                style={{
                  textDecoration: "underline",
                  fontSize: 18,
                  fontWeight: 400,
                }}
              >
                Update Employee Details
              </label>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form fluid>
              <Form.Group>
                <Form.ControlLabel>Name</Form.ControlLabel>
                <Form.Control
                  name="name"
                  value={updateLabour.Name}
                  onChange={(e) => {
                    setUpdateLabour((old) => {
                      return { ...old, Name: e };
                    });
                    //setText(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group>
                <Form.ControlLabel>Supervisor</Form.ControlLabel>
                <SelectPicker
                  data={usersList}
                  cleanable={false}
                  searchable={false}
                  appearance="default"
                  placeholder="Select Supervisor"
                  defaultValue={updateLabour.Supervisor}
                  style={{ width: 200 }}
                  key="id"
                  sort={() => {
                    return (a, b) => {
                      return compare(a.value, b.value);
                    };
                  }}
                  onSelect={(e, Item) => {
                    setUpdateLabour((old) => {
                      return {
                        ...old,
                        Supervisor: e,
                        SupervisorID: Item.id,
                      };
                    });
                  }}
                  value={updateLabour.Supervisor}
                />
              </Form.Group>

              <Form.Group>
                <Form.ControlLabel>Descriptions</Form.ControlLabel>
                <Form.Control
                  rows={1}
                  name="textarea"
                  accepter={Textarea}
                  style={{ resize: "none" }}
                  value={updateLabour.Note}
                  onChange={(e) => {
                    setUpdateLabour((a) => {
                      return { ...a, Note: e };
                    });
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Type</Form.ControlLabel>
                <Form.Control
                  rows={1}
                  name="textarea"
                  accepter={Textarea}
                  style={{ resize: "none" }}
                  value={updateLabour.Designation}
                  onChange={(e) => {
                    setUpdateLabour((a) => {
                      return { ...a, Designation: e };
                    });
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Mobile</Form.ControlLabel>
                <Form.Control
                  rows={1}
                  name="textarea"
                  accepter={Textarea}
                  style={{ resize: "none" }}
                  value={updateLabour.Mobile}
                  onChange={(e) => {
                    setUpdateLabour((a) => {
                      return { ...a, Mobile: e };
                    });
                  }}
                />
              </Form.Group>

              <Form.Group>
                <InputGroup style={{ width: "100%" }}>
                  <InputGroup.Addon>Rate (₹)</InputGroup.Addon>
                  <Input
                    value={updateLabour.Rate}
                    onChange={(e) => {
                      setUpdateLabour((a) => {
                        return { ...a, Rate: e };
                      });
                    }}
                  />
                  &nbsp;
                  <InputGroup.Addon>OTRate (₹)</InputGroup.Addon>
                  <Input
                    value={updateLabour.OTRate}
                    onChange={(e) => {
                      setUpdateLabour((a) => {
                        return { ...a, OTRate: e };
                      });
                    }}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>
                  {processing1 ? "Uploading " : "Docs"}
                </Form.ControlLabel>
                <input
                  type="file"
                  name="file"
                  onChange={(e) => setDocsCopy(e.target.files[0])}
                ></input>
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Effective From:</Form.ControlLabel>

                <DatePicker
                  cleanable={false}
                  appearance="default"
                  placeholder="Select Effective From Date"
                  format="dd-MM-yyyy"
                  oneTap
                  style={{ width: 250 }}
                  value={updateLabour.EffectiveFrom}
                  onChange={(e) => {
                    console.log(e);
                    setUpdateLabour((old) => {
                      return { ...old, EffectiveFrom: e };
                    });
                  }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={saveUpdated} appearance="primary">
              Submit
            </Button>
            <Button onClick={handleClose} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default ViewLabours;
