import axios from "axios";

const apiUrl = "https://backend.ashaelectricals.in/";
// Create a new Axios instance
const privateAxios = axios.create({
  baseURL: apiUrl,
  timeout: 5000, // Adjust the timeout as needed
  withCredentials: true,
  headers: {
    "Content-type": "application/json",
  },
});

// Add a request interceptor to the new Axios instance
privateAxios.interceptors.request.use(
  async (config) => {
    // Get cookies using react-native-cookies
    const SessionID = JSON.parse(sessionStorage.getItem("access"));

    // Set access token from Redux store in the request headers
    if (SessionID) {
      config.headers.Authorization = `Bearer ${SessionID}`;
    }
    console.info(config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

privateAxios.interceptors.response.use(
  (response) => {
    //console.log(response);
    if (response.status === 401) {
      alert("You are not authorized");
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

export default privateAxios;
