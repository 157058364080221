import { SET_USER, LOGGED_IN, LOGGED_OUT } from "../type";

const INITIAL_STATE = {
  user: { displayName: "", email: "", uid: "", userDetails: {} },
  IsLoggedIn: false,
};

const reduce = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER:
      sessionStorage.setItem(
        "access",
        JSON.stringify(action.payload.SessionID)
      );
      return {
        ...state,
        IsLoggedIn: true,
        user: {
          displayName: action.payload.Name,
          email: action.payload.Email,
          uid: action.payload.UniqueID,
          userDetails: action.payload.userDetails,
        },
      };
    case LOGGED_IN:
      return {
        ...state,
        IsLoggedIn: true,
      };
    case LOGGED_OUT:
      sessionStorage.removeItem("access");
      return {
        ...state,
        IsLoggedIn: false,
        user: {
          displayName: "",
          email: "",
          uid: "",
        },
      };
    default:
      return state;
  }
};

export default reduce;
