import React, { useState, useEffect } from "react";
import Header from "../component/Header";

import {
  Button,
  Form,
  Radio,
  RadioGroup,
  SelectPicker,
  useToaster,
  Notification,
  IconButton,
  DatePicker,
  Modal,
  InputGroup,
  Input,
  Stack,
} from "rsuite";
import SearchIcon from "@rsuite/icons/Search";

import Table from "react-bootstrap/Table";
import moment from "moment";

import { firstDay } from "../Constants";

import { GetLastDay, Table2XLSX, sendFCMPushNotification } from "../functions";
import CalmButton from "../component/CalmButton";

import { useParams } from "react-router-dom";
import privateAxios from "../Axios/axiosConfig";

const GetSalary = () => {
  const [open3, setOpen3] = React.useState(false);
  const [radio, setRadio] = useState("A");
  const [selectedDate, setselectedDate] = useState(new Date());
  const [selectedNameID, setselectedNameID] = useState("");
  const [LabourList, setLabourList] = useState([]);
  const [processing, setProcessing] = useState(false);

  const [selectedDate2, setselectedDate2] = useState(new Date());

  let { Site } = useParams();
  /* const [processing2, setProcessing2] = useState(false); */
  const [selectedName, setselectedName] = useState("");
  const [datalist1, setdataList1] = useState([]);
  const [datalist2, setdataList2] = useState([]);
  const [datalist3, setdataList3] = useState([]);

  const [salary, setSalary] = useState({});
  const [balance, setBalance] = useState(0);
  const toaster = useToaster();
  const [selectedLabour, setSelectedLabour] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [usersListPayer, setUsersListPayer] = useState([]);

  const [count, setCount] = useState({});
  const [radio1, setRadio1] = useState("Payments");
  const [sitename, setSitename] = useState();
  const [siteList, setSiteList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const [travel, setTravel] = useState("");
  const [selectedLabourID, setSelectedLabourID] = useState("");

  const [value, setValue] = React.useState({
    Recipient: "",
    Date: new Date(),
    Category: "Other",
    Amount: "",
    Desc: "",
  });
  const [Mode, setMode] = useState("");
  const [category, setCategory] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  let i = 0;

  const categories = [
    {
      key: ++i,
      label: "Salary",
      value: "Salary",
    },

    {
      key: ++i,
      label: "Advance",
      value: "Advance",
    },

    {
      key: ++i,
      label: "EMI",
      value: "EMI",
    },
  ];

  const [desc, setDesc] = useState("");

  useEffect(() => {
    if (Site) {
      setRadio("D");
      setSitename(Site);
    }
  }, []);

  const handleOpen = () => {
    if (selectedLabour === "" || selectedLabourID === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          {" "}
          Please select Labour first.{" "}
        </Notification>,
        {
          placement: "topEnd",
        }
      );
      return false;
    }

    if (selectedDate === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          {" "}
          Please select month.{" "}
        </Notification>,
        {
          placement: "topEnd",
        }
      );
      return false;
    }

    setOpen(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleOpen3 = () => {
    setOpen3(true);
  };

  const column = React.useMemo(
    () => [
      {
        field: "Date",
        title: "Date",
      },

      {
        field: "Category",
        title: "Category",
      },

      {
        field: "Amount",
        title: "Amount",
      },

      {
        field: "PaidBy",
        title: "PaidBy",
      },

      {
        field: "Note",
        title: "Note",
      },
    ],
    []
  );

  const column2 = React.useMemo(
    () => [
      {
        field: "Name",
        title: "Name",
      },

      {
        field: "Date",
        title: "Date",
      },

      {
        field: "Attendance",
        title: "Attendance",
      },

      {
        field: "Rate",
        title: "Rate",
      },

      {
        field: "Salary",
        title: "Salary",
      },

      {
        field: "OT",
        title: "OT",
      },

      {
        field: "Total",
        title: "Total",
      },
    ],
    []
  );

  const column3 = React.useMemo(
    () => [
      {
        field: "Date",
        title: "Date",
      },

      {
        field: "Attendance",
        title: "Attendance",
      },

      {
        field: "Overtime",
        title: "Overtime",
      },

      {
        field: "Status",
        title: "Status",
      },

      {
        field: "Rate",
        title: "Rate",
      },

      {
        field: "Total",
        title: "Total",
      },
    ],
    []
  );

  function compare(a, b) {
    let nameA = a.toUpperCase();
    let nameB = b.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    return 0;
  }

  const handleSupwiseAttendance = () => {
    const dt = GetLastDay(selectedDate);
    let datatoSend = {
      SupID: selectedNameID,
      Month: moment(dt).format("YYYY-MM-DD"),
    };

    privateAxios
      .post("/GetAttendanceBySupervisor.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setdataList2(data1.Data);
          setCount(data1.Total);
        }

        if (data1.status === 0) {
          setdataList2([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSitewiseAttendance = () => {
    let datatoSend = {
      Site: sitename,
    };

    privateAxios
      .post("/GetAttendanceBySite.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setdataList2(data1.Data);
          setCount(data1.Total);
        }

        if (data1.status === 0) {
          setdataList2([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getLabourList = () => {
    setLabourList([]);

    privateAxios
      .post("/GetLaboursForWeb.php")
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setLabourList(data1.Data);
        }

        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      getUsersList();
      getLabourList();
      getSitesList();
      getUsersListPayer();
    }

    return () => {};
  }, []);

  const handleRadio1 = (e) => {
    setRadio1(e);
    /* if (e === "A") {
    }
    if (e === "D") {
      getUsersList();
    } */
  };

  const handleRadio = (e) => {
    setRadio(e);

    if (e === "A") {
      getLabourList();
    }

    if (e === "D") {
      getUsersList();
    }
  };

  const handleSups = () => {
    handleSupwiseAttendance();
  };

  const getUsersList = () => {
    setUsersList([]);

    privateAxios
      .post("/GetSupsList.php")
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setUsersList(data1.Data);
        }

        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getTravel = () => {
    const lastDate = GetLastDay(selectedDate);

    const datatoSend = {
      Month: moment(lastDate).format("YYYY-MM-DD"),
      ID: selectedLabourID,
    };

    console.log(datatoSend);

    privateAxios
      .post("/GetTravelAllowance.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setTravel(data1.Travel);
        }

        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleAttendanceforLabour = () => {
    if (selectedLabourID === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          {" "}
          Please select Employee name{" "}
        </Notification>,
        {
          placement: "topEnd",
        }
      );
      setProcessing(false);
      return;
    }

    if (selectedDate === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          {" "}
          Please select month{" "}
        </Notification>,
        {
          placement: "topEnd",
        }
      );
      setProcessing(false);
      return;
    }

    console.log(GetLastDay(selectedDate));

    const datatoSend = {
      ID: selectedLabourID,
      Month: moment(GetLastDay(selectedDate)).format("YYYY-MM-DD"),
    };

    privateAxios
      .post("/GetMonthlyDataEmployeeWeb.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;
        console.log(data1);

        if (data1.status === 1) {
          setdataList3(data1.Arr);
          setdataList1(data1.Payments);
        }

        if (data1.status === 0) {
          setdataList3([]);
        }
      })
      .catch(function (error) {
        console.log(error);
        setdataList3([]);
      });
  };

  const handleSearchforLabour = () => {
    setdataList1([]);
    handleAttendanceforLabour();

    if (selectedLabourID === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          {" "}
          Please enter Labour name{" "}
        </Notification>,
        {
          placement: "topEnd",
        }
      );
      setProcessing(false);
      return;
    }

    if (selectedDate === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          {" "}
          Please select month{" "}
        </Notification>,
        {
          placement: "topEnd",
        }
      );
      setProcessing(false);
      return;
    }

    console.log(GetLastDay(selectedDate));

    const datatoSend = {
      Labour: selectedLabourID,
      Date: moment(GetLastDay(selectedDate)).format("YYYY-MM-DD"),
    };

    //console.log(datatoSend);
    privateAxios
      .post("/GetSalaryForLabourWeb.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setSalary(data1);
        }

        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              {" "}
              No records{" "}
            </Notification>,
            {
              placement: "topEnd",
            }
          );
        }
      })
      .catch(function (error) {
        console.log(error);

        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            {" "}
            {error}
          </Notification>,
          {
            placement: "topEnd",
          }
        );
      });

    /*     privateAxios
      .post("/GetAllTransactionsForLabour.php", {
        LabourID: selectedLabourID,
      })
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          // console.log(data1.Data);
          setdataList1(data1.Data);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              No records
            </Notification>,
            { placement: "topEnd" }
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            {error}
          </Notification>,
          { placement: "topEnd" }
        );
      }); */

    privateAxios
      .post("/GetSalaryBalanceLabour.php", {
        ID: selectedLabourID,
        Date: moment(GetLastDay(selectedDate)).format("YYYY-MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setBalance(data1);
        }

        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              {" "}
              No records{" "}
            </Notification>,
            {
              placement: "topEnd",
            }
          );
        }
      })
      .catch(function (error) {
        console.log(error);

        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            {" "}
            {error}
          </Notification>,
          {
            placement: "topEnd",
          }
        );
      });
    getTravel();
  };

  const getSitesList = () => {
    privateAxios
      .post("/GetSitesForDropdown.php")
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setSiteList(data1.Data);
        }

        if (data1.status === 0) {
          setSiteList([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleAddTravel = () => {
    console.log(selectedLabourID);

    if (selectedLabour === "" || selectedLabourID === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          {" "}
          Please selecte Labour first{" "}
        </Notification>,
        {
          placement: "topEnd",
        }
      );
      return false;
    }

    if (selectedDate === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          {" "}
          Please select month.{" "}
        </Notification>,
        {
          placement: "topEnd",
        }
      );
      return false;
    }

    const dt = GetLastDay(selectedDate);

    const datatoSend = {
      LabourID: selectedLabourID,
      Date: moment(dt).format("YYYY-MM-DD"),
      Amount: amount,
      LabourName: selectedLabour,
    };

    try {
      console.log(datatoSend);

      privateAxios
        .post("/AddTravelAllowance.php", datatoSend)
        .then((response) => {
          const result = response.data;
          console.log(result);

          if (result.status === 1) {
            toaster.push(
              <Notification type="success" header="success" duration={1000}>
                {" "}
                Saved successfully{" "}
              </Notification>,
              {
                placement: "topEnd",
              }
            );
            handleClose();
          }

          if (result.status === 0) {
            toaster.push(
              <Notification type="error" header="Error" duration={1000}>
                {" "}
                {result.Error}
              </Notification>,
              {
                placement: "topEnd",
              }
            );
            handleClose();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          {" "}
          Error occured: {e.Message}
        </Notification>,
        {
          placement: "topEnd",
        }
      );
    } finally {
      setProcessing(false);
      handleSearchforLabour();
    }
  };

  const PayBySupervisor = () => {
    try {
      const datatoSend = {
        Name: selectedLabour,
        Date: moment(selectedDate2).format("YYYY-MM-DD"),
        Amount: amount,
        Month: moment(selectedDate2).format("YYYY-MM-DD"),
        Desc: `$ {
          value.Desc
        }

        // ${Mode}`,

        NameID: selectedLabourID,
        RecipientType: "Labour",
        Type: "Debit",
        Category: category,
      };

      privateAxios
        .post("/AppExpense.php", datatoSend)
        .then((response) => {
          const result = response.data;

          if (result.status === 1) {
            /* sendFCMPushNotification(
              "Labour New Payment",
              `Labour : ${datatoSend.Name},Date:${datatoSend.Date}, Amount : ${datatoSend.Amount}, Category : ${datatoSend.Category}, Date : ${datatoSend.Date}, Desc : ${datatoSend.Desc}, Added By : WebApp`,
              "Admin"
            ); */
            toaster.push(
              <Notification type="success" header="Success" duration={1000}>
                {" "}
                Transaction recorded successfully.{" "}
              </Notification>,
              {
                placement: "topEnd",
              }
            );
            handleClose3();
          }

          if (result.status === 0) {
            toaster.push(
              <Notification type="error" header="Error" duration={1000}>
                {" "}
                Error occured{" "}
              </Notification>,
              {
                placement: "topEnd",
              }
            );
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getUsersListPayer = () => {
    setUsersListPayer([]);

    privateAxios
      .post("/GetUsersForDropDown.php")
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setUsersListPayer(data1.Data);
        }

        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      {" "}
      <Header>
        {" "}
        <h4
          style={{
            color: "white",
            fontSize: 25,
          }}
        >
          Calculate Salary
        </h4>{" "}
      </Header>{" "}
      <div className="InputContainer">
        {" "}
        <Form.Group controlId="radioList">
          {" "}
          <RadioGroup
            name="radioList"
            inline
            appearance="picker"
            defaultValue="A"
            onChange={(e) => {
              handleRadio(e);
            }}
            value={radio}
          >
            {" "}
            <Radio value="A">Report by Labour</Radio>{" "}
            <Radio value="C">Report by Supervisor </Radio>{" "}
            <Radio value="D">Report by Site </Radio>{" "}
          </RadioGroup>{" "}
          <hr />{" "}
        </Form.Group>{" "}
        <Form layout="inline">
          {" "}
          {radio === "A" && (
            <Stack spacing={10}>
              {" "}
              <label>Date:</label>{" "}
              <DatePicker
                oneTap
                defaultValue={firstDay}
                format="MMM-yyyy"
                ranges={[]}
                onSelect={(e) => setselectedDate(e)}
                style={{
                  width: 130,
                }}
                cleanable={false}
              />{" "}
              <label>Labour Name:</label>{" "}
              <SelectPicker
                cleanable={false}
                data={LabourList}
                appearance="default"
                placeholder="Select Labour"
                block={true}
                value={selectedLabour}
                onSelect={(e, Item) => {
                  console.log(Item);
                  setSelectedLabour(e);
                  setSelectedLabourID(Item.id);
                }}
              />{" "}
              <IconButton
                onClick={handleSearchforLabour}
                loading={processing}
                icon={<SearchIcon />}
              >
                {" "}
                Search{" "}
              </IconButton>{" "}
              <Button
                style={{
                  marginHorizontal: 10,
                }}
                color="blue"
                appearance="primary"
                onClick={handleOpen}
              >
                {" "}
                Travel{" "}
              </Button>{" "}
              <p>
                {" "}
                <span
                  style={{
                    fontSize: 16,
                  }}
                >
                  Travel
                </span>{" "}
                {` : ${Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(travel)}

          `}
              </p>{" "}
              <Button
                style={{
                  marginHorizontal: 10,
                }}
                color="blue"
                appearance="primary"
                onClick={handleOpen3}
              >
                {" "}
                New Payment{" "}
              </Button>{" "}
            </Stack>
          )}
          {radio === "C" && (
            <Stack spacing={10}>
              {" "}
              <label>Supervisor:</label>{" "}
              <SelectPicker
                cleanable={false}
                data={usersList}
                appearance="default"
                placeholder="Select Supervisor"
                style={{
                  width: 200,
                }}
                key="id"
                sort={() => {
                  return (a, b) => {
                    return compare(a.value, b.value);
                  };
                }}
                onSelect={(e, Item) => {
                  setselectedName(e);
                  setselectedNameID(Item.id);
                }}
                value={selectedName}
              />{" "}
              <DatePicker
                oneTap
                cleanable={false}
                format="MMM-yyyy"
                ranges={[]}
                onSelect={(e) => setselectedDate(e)}
                style={{
                  width: 130,
                }}
              />{" "}
              <Button color="blue" appearance="default" onClick={handleSups}>
                {" "}
                Search{" "}
              </Button>{" "}
              <Table2XLSX />{" "}
              <span
                style={{
                  fontSize: 17,
                }}
              >
                Total
              </span>{" "}
              {` : ${Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 2,
              }).format(count.TotalAmount)}

          `}
            </Stack>
          )}
          {radio === "D" && (
            <Stack spacing={10}>
              {" "}
              <label>Site:</label>{" "}
              <SelectPicker
                cleanable={false}
                data={siteList}
                appearance="default"
                placeholder="Select Site"
                style={{
                  width: 200,
                }}
                key="id"
                sort={() => {
                  return (a, b) => {
                    return compare(a.value, b.value);
                  };
                }}
                onSelect={(e, Item) => {
                  setSitename(e);
                }}
                value={sitename}
              />{" "}
              <Button
                color="blue"
                appearance="default"
                onClick={handleSitewiseAttendance}
              >
                {" "}
                Search{" "}
              </Button>{" "}
              <Table2XLSX />{" "}
              <span
                style={{
                  fontSize: 17,
                }}
              >
                Total
              </span>{" "}
              {`${Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 2,
              }).format(count.TotalAmount)}`}
            </Stack>
          )}
        </Form>{" "}
      </div>{" "}
      <hr />{" "}
      <div className="InputContainer">
        {" "}
        {radio === "A" && (
          <div
            style={{
              flex: 1,
              flexDirection: "row",
              display: "flex",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            {" "}
            <div
              style={{
                flex: 0.6,
                display: "flex",
                padding: 10,
                flexDirection: "column",
              }}
            >
              {" "}
              <Form.Group controlId="radioList1">
                {" "}
                <RadioGroup
                  name="radioList1"
                  inline
                  appearance="picker"
                  defaultValue="Payment"
                  onChange={(e) => {
                    handleRadio1(e);
                  }}
                  value={radio1}
                >
                  {" "}
                  <Radio value="Payment">Payments</Radio>{" "}
                  <Radio value="Attendance">Attendance </Radio>{" "}
                </RadioGroup>{" "}
                <hr />{" "}
              </Form.Group>{" "}
              <Header>
                {" "}
                <h6
                  style={{
                    color: "white",
                    fontSize: 15,
                  }}
                >
                  {" "}
                  {radio1}
                </h6>{" "}
              </Header>{" "}
              <div
                style={{
                  flex: 1,
                  overflow: "auto",
                }}
              >
                {" "}
                {radio1 === "Payment" ? (
                  <Table striped bordered hover id="TableData">
                    {" "}
                    <thead>
                      {" "}
                      <tr>
                        {" "}
                        {column.map((a) => (
                          <th key={a.field}> {a.title}</th>
                        ))}
                      </tr>{" "}
                    </thead>{" "}
                    <tbody>
                      {" "}
                      {datalist1.map((a) => (
                        <tr key={i++}>
                          {" "}
                          <td> {moment(a.Date).format("DD-MM-YYYY")}</td>{" "}
                          <td> {a.Category}</td>{" "}
                          <td>
                            {" "}
                            {Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: "INR",
                              minimumFractionDigits: 2,
                            }).format(a.Amount)}
                          </td>{" "}
                          <td> {a.PaidBy}</td> <td> {a.Description}</td>{" "}
                        </tr>
                      ))}
                    </tbody>{" "}
                  </Table>
                ) : (
                  <Table striped bordered hover id="TableData">
                    {" "}
                    <thead>
                      {" "}
                      <tr>
                        {" "}
                        {column3.map((a) => (
                          <th key={a.field}> {a.title}</th>
                        ))}
                      </tr>{" "}
                    </thead>{" "}
                    <tbody>
                      {" "}
                      {datalist3.map((a) => (
                        <tr key={i++}>
                          {" "}
                          <td> {a.Date}</td> <td> {a.Present}</td>{" "}
                          <td> {a.Overtime}</td> <td> {a.Status}</td>{" "}
                          <td>
                            {" "}
                            {Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: "INR",
                              minimumFractionDigits: 2,
                            }).format(a.Rate)}
                          </td>{" "}
                          <td>
                            {" "}
                            {Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: "INR",
                              minimumFractionDigits: 2,
                            }).format(a.Rate * a.Attendance)}
                          </td>{" "}
                        </tr>
                      ))}
                    </tbody>{" "}
                  </Table>
                )}
              </div>{" "}
            </div>{" "}
            <div
              style={{
                flex: 0.4,
                display: "flex",
                padding: 10,
                flexDirection: "column",
              }}
            >
              {" "}
              <div>
                {" "}
                <Header>
                  {" "}
                  <h6
                    style={{
                      color: "white",
                      fontSize: 20,
                    }}
                  >
                    Salary
                  </h6>{" "}
                </Header>{" "}
              </div>{" "}
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                {" "}
                <div
                  style={{
                    width: "100%",
                    height: 50,
                    border: 1,
                    borderColor: "#aaa",
                    borderStyle: "solid",
                    marginTop: 10,
                    marginBottom: 10,
                    borderRadius: 5,
                    justifyContent: "space-between",
                    paddingLeft: 10,
                    paddingRight: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <h6
                    style={{
                      display: "inline-block",
                      color: "#1E1E1E",
                    }}
                  >
                    {" "}
                    Attendance{" "}
                  </h6>{" "}
                  <h6
                    style={{
                      display: "inline-block",
                      color: "#1E1E1E",
                    }}
                  >
                    {" "}
                    {`Present - ${salary.FullDay} // OT- ${salary.OT}`}
                  </h6>{" "}
                </div>{" "}
                <div
                  style={{
                    width: "100%",
                    height: 50,
                    border: 1,
                    borderColor: "#aaa",
                    borderStyle: "solid",
                    marginTop: 10,
                    marginBottom: 10,
                    borderRadius: 5,
                    justifyContent: "space-between",
                    paddingLeft: 10,
                    paddingRight: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <h6
                    style={{
                      display: "inline-block",
                      color: "#1E1E1E",
                    }}
                  >
                    {" "}
                    Salary Due{" "}
                  </h6>{" "}
                  <h6
                    style={{
                      display: "inline-block",
                      color: "#1E1E1E",
                    }}
                  >
                    {" "}
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(salary.Salary_Due)}
                  </h6>{" "}
                </div>{" "}
                {!isNaN(travel) && travel > 0 && (
                  <div
                    style={{
                      width: "100%",
                      height: 50,
                      border: 1,
                      borderColor: "#aaa",
                      borderStyle: "solid",
                      marginTop: 10,
                      marginBottom: 10,
                      borderRadius: 5,
                      justifyContent: "space-between",
                      paddingLeft: 10,
                      paddingRight: 10,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <h6
                      style={{
                        display: "inline-block",
                        color: "#1E1E1E",
                      }}
                    >
                      {" "}
                      Travel{" "}
                    </h6>{" "}
                    <h6
                      style={{
                        display: "inline-block",
                        color: "#1E1E1E",
                      }}
                    >
                      {" "}
                      {Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                      }).format(travel)}
                    </h6>{" "}
                  </div>
                )}
                <div
                  style={{
                    width: "100%",
                    height: 50,
                    border: 1,
                    borderColor: "#aaa",
                    borderStyle: "solid",
                    marginTop: 10,
                    marginBottom: 10,
                    borderRadius: 5,
                    justifyContent: "space-between",
                    paddingLeft: 10,
                    paddingRight: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <h6
                    style={{
                      display: "inline-block",
                      color: "#1E1E1E",
                    }}
                  >
                    {" "}
                    Balance{" "}
                  </h6>{" "}
                  <h6
                    style={{
                      display: "inline-block",
                      color: balance.Balance > 0 ? "red" : "limegreen",
                    }}
                  >
                    {" "}
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(balance.Balance)}
                  </h6>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>
        )}
        {radio === "C" && (
          <div
            style={{
              flex: 1,
              flexDirection: "row",
              display: "flex",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            {" "}
            <div
              style={{
                flex: 0.7,
                display: "flex",
                padding: 10,
                flexDirection: "column",
              }}
            >
              {" "}
              <Header>
                {" "}
                <h6
                  style={{
                    color: "white",
                    fontSize: 20,
                  }}
                >
                  All Attendance
                </h6>{" "}
              </Header>{" "}
              <div
                style={{
                  flex: 1,
                  overflow: "auto",
                }}
              >
                {" "}
                <Table striped bordered hover id="TableData">
                  {" "}
                  <thead>
                    {" "}
                    <tr>
                      {" "}
                      {column2.map((a) => (
                        <th key={a.field}> {a.title}</th>
                      ))}
                    </tr>{" "}
                  </thead>{" "}
                  <tbody>
                    {" "}
                    {datalist2.map((a) => (
                      <tr key={a.SrNo}>
                        {" "}
                        <td> {a.Name}</td> <td> {a.Date}</td>{" "}
                        <td> {a.Attendance}</td>{" "}
                        <td>
                          {" "}
                          {Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 2,
                          }).format(a.Rate)}
                        </td>{" "}
                        <td>
                          {" "}
                          {Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 2,
                          }).format(a.Salary)}
                        </td>{" "}
                        <td>
                          {" "}
                          {Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 2,
                          }).format(a.OT)}
                        </td>{" "}
                        <td>
                          {" "}
                          {Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 2,
                          }).format(a.Total)}
                        </td>{" "}
                      </tr>
                    ))}
                  </tbody>{" "}
                </Table>{" "}
              </div>{" "}
            </div>{" "}
            <div
              style={{
                flex: 0.3,
                display: "flex",
                padding: 10,
                flexDirection: "column",
              }}
            >
              {" "}
              <div>
                {" "}
                <Header>
                  {" "}
                  <h6
                    style={{
                      color: "white",
                      fontSize: 20,
                    }}
                  >
                    Total Amount
                  </h6>{" "}
                </Header>{" "}
              </div>{" "}
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                {" "}
                <div
                  style={{
                    width: "100%",
                    height: 50,
                    border: 1,
                    borderColor: "#aaa",
                    borderStyle: "solid",
                    marginTop: 10,
                    marginBottom: 10,
                    borderRadius: 5,
                    justifyContent: "space-between",
                    paddingLeft: 10,
                    paddingRight: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <h6
                    style={{
                      display: "inline-block",
                    }}
                  >
                    Labour
                  </h6>{" "}
                  <h6
                    style={{
                      display: "inline-block",
                    }}
                  >
                    {" "}
                    {count.LabourCount}
                  </h6>{" "}
                </div>{" "}
                <div
                  style={{
                    width: "100%",
                    height: 50,
                    border: 1,
                    borderColor: "#aaa",
                    borderStyle: "solid",
                    marginTop: 10,
                    marginBottom: 10,
                    borderRadius: 5,
                    justifyContent: "space-between",
                    paddingLeft: 10,
                    paddingRight: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <h6
                    style={{
                      display: "inline-block",
                    }}
                  >
                    Attendance
                  </h6>{" "}
                  <h6
                    style={{
                      display: "inline-block",
                    }}
                  >
                    {" "}
                    {count.TotalAttendance}
                  </h6>{" "}
                </div>{" "}
                <div
                  style={{
                    width: "100%",
                    height: 50,
                    border: 1,
                    borderColor: "#aaa",
                    borderStyle: "solid",
                    marginTop: 10,
                    marginBottom: 10,
                    borderRadius: 5,
                    justifyContent: "space-between",
                    paddingLeft: 10,
                    paddingRight: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <h6
                    style={{
                      display: "inline-block",
                    }}
                  >
                    Amount{" "}
                  </h6>{" "}
                  <h6
                    style={{
                      display: "inline-block",
                    }}
                  >
                    {" "}
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(count.TotalAmount)}
                  </h6>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>
        )}
        {radio === "D" && (
          <div
            style={{
              flex: 1,
              flexDirection: "row",
              display: "flex",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            {" "}
            <div
              style={{
                flex: 0.7,
                display: "flex",
                padding: 10,
                flexDirection: "column",
              }}
            >
              {" "}
              <Header>
                {" "}
                <h6
                  style={{
                    color: "white",
                    fontSize: 20,
                  }}
                >
                  All Attendance
                </h6>{" "}
              </Header>{" "}
              <div
                style={{
                  flex: 1,
                  overflow: "auto",
                }}
              >
                {" "}
                <Table striped bordered hover id="TableData">
                  {" "}
                  <thead>
                    {" "}
                    <tr>
                      {" "}
                      {column2.map((a) => (
                        <th key={a.field}> {a.title}</th>
                      ))}
                    </tr>{" "}
                  </thead>{" "}
                  <tbody>
                    {" "}
                    {datalist2.map((a) => (
                      <tr key={`${a.SrNo} -${a.Date}`}>
                        {" "}
                        <td> {a.Name}</td> <td> {a.Date}</td>{" "}
                        <td> {a.Attendance}</td>{" "}
                        <td>
                          {" "}
                          {Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 2,
                          }).format(a.Rate)}
                        </td>{" "}
                        <td>
                          {" "}
                          {Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 2,
                          }).format(a.Salary)}
                        </td>{" "}
                        <td>
                          {" "}
                          {Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 2,
                          }).format(a.OT)}
                        </td>{" "}
                        <td>
                          {" "}
                          {Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 2,
                          }).format(a.Total)}
                        </td>{" "}
                      </tr>
                    ))}
                  </tbody>{" "}
                </Table>{" "}
              </div>{" "}
            </div>{" "}
            <div
              style={{
                flex: 0.3,
                display: "flex",
                padding: 10,
                flexDirection: "column",
              }}
            >
              {" "}
              <div>
                {" "}
                <Header>
                  {" "}
                  <h6
                    style={{
                      color: "white",
                      fontSize: 20,
                    }}
                  >
                    Total Amount
                  </h6>{" "}
                </Header>{" "}
              </div>{" "}
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                {" "}
                <div
                  style={{
                    width: "100%",
                    height: 50,
                    border: 1,
                    borderColor: "#aaa",
                    borderStyle: "solid",
                    marginTop: 10,
                    marginBottom: 10,
                    borderRadius: 5,
                    justifyContent: "space-between",
                    paddingLeft: 10,
                    paddingRight: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <h6
                    style={{
                      display: "inline-block",
                    }}
                  >
                    Labour
                  </h6>{" "}
                  <h6
                    style={{
                      display: "inline-block",
                    }}
                  >
                    {" "}
                    {count.LabourCount}
                  </h6>{" "}
                </div>{" "}
                <div
                  style={{
                    width: "100%",
                    height: 50,
                    border: 1,
                    borderColor: "#aaa",
                    borderStyle: "solid",
                    marginTop: 10,
                    marginBottom: 10,
                    borderRadius: 5,
                    justifyContent: "space-between",
                    paddingLeft: 10,
                    paddingRight: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <h6
                    style={{
                      display: "inline-block",
                    }}
                  >
                    Attendance
                  </h6>{" "}
                  <h6
                    style={{
                      display: "inline-block",
                    }}
                  >
                    {" "}
                    {count.TotalAttendance}
                  </h6>{" "}
                </div>{" "}
                <div
                  style={{
                    width: "100%",
                    height: 50,
                    border: 1,
                    borderColor: "#aaa",
                    borderStyle: "solid",
                    marginTop: 10,
                    marginBottom: 10,
                    borderRadius: 5,
                    justifyContent: "space-between",
                    paddingLeft: 10,
                    paddingRight: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <h6
                    style={{
                      display: "inline-block",
                    }}
                  >
                    Amount{" "}
                  </h6>{" "}
                  <h6
                    style={{
                      display: "inline-block",
                    }}
                  >
                    {" "}
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(count.TotalAmount)}
                  </h6>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>
        )}
      </div>{" "}
      {/* Description: ===================================================== */}
      <div>
        {" "}
        <Modal open={open} onClose={handleClose} size="xs">
          {" "}
          <Modal.Header>
            {" "}
            <Modal.Title
              style={{
                fontSize: 18,
                fontWeight: 500,
                textDecoration: "underline",
                textAlign: "center",
              }}
            >
              {" "}
              {`Travel - $ {
          selectedLabour
        }

        `}
            </Modal.Title>{" "}
          </Modal.Header>{" "}
          <Modal.Body>
            {" "}
            <Form layout="inline" fluid>
              {" "}
              <Form.Group controlId="textarea-9">
                {" "}
                <Form.ControlLabel>Amount : </Form.ControlLabel>{" "}
                <InputGroup
                  style={{
                    width: "200px",
                  }}
                >
                  {" "}
                  <InputGroup.Addon>₹</InputGroup.Addon>{" "}
                  <Input
                    id="textarea-9"
                    value={amount}
                    onChange={(e) => {
                      setAmount(e);
                    }}
                  />{" "}
                  <InputGroup.Addon>.00</InputGroup.Addon>{" "}
                </InputGroup>{" "}
              </Form.Group>{" "}
            </Form>{" "}
          </Modal.Body>{" "}
          <Modal.Footer>
            {" "}
            <Button onClick={handleAddTravel} appearance="primary">
              {" "}
              Submit{" "}
            </Button>{" "}
            <Button onClick={handleClose} appearance="subtle">
              {" "}
              Cancel{" "}
            </Button>{" "}
          </Modal.Footer>{" "}
        </Modal>{" "}
      </div>{" "}
      {/* ============================Payments by Supervisor========================== */}
      <div>
        {" "}
        <Modal open={open3} onClose={handleClose3} size="xs">
          {" "}
          <Modal.Header>
            {" "}
            <Modal.Title
              style={{
                fontSize: 18,
                fontWeight: 500,
                textDecoration: "underline",
                textAlign: "center",
              }}
            >
              {" "}
              {`Payments to $ {
          selectedLabour
        }

        `}
            </Modal.Title>{" "}
          </Modal.Header>{" "}
          <Modal.Body>
            {" "}
            <table
              style={{
                borderCollapse: "separate",
                borderSpacing: "0 18px",
              }}
            >
              {" "}
              <tr>
                {" "}
                <td
                  style={{
                    flex: 1,
                    paddingRight: 10,
                    textAlign: "end",
                  }}
                >
                  {" "}
                  <label>Name :</label>{" "}
                </td>{" "}
                <td
                  style={{
                    flex: 2,
                  }}
                >
                  {" "}
                  <SelectPicker
                    cleanable={false}
                    data={LabourList}
                    appearance="default"
                    placeholder="Select Labour"
                    defaultValue={selectedLabour}
                    block={true}
                    key="id"
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      setSelectedLabour(e);
                      setSelectedLabourID(Item.id);
                    }}
                    value={selectedLabour}
                  />{" "}
                </td>{" "}
              </tr>{" "}
              <tr>
                {" "}
                <td
                  style={{
                    flex: 1,
                    paddingRight: 10,
                    textAlign: "end",
                  }}
                >
                  {" "}
                  <label>Date :</label>{" "}
                </td>{" "}
                <td
                  style={{
                    flex: 2,
                  }}
                >
                  {" "}
                  <DatePicker
                    cleanable={false}
                    appearance="default"
                    format="dd-MM-yyyy"
                    placeholder="Select Date"
                    defaultValue={new Date()}
                    oneTap
                    style={{
                      width: 150,
                    }}
                    value={selectedDate2}
                    onChange={(e) => {
                      setselectedDate2(e);
                    }}
                  />{" "}
                </td>{" "}
              </tr>{" "}
              <tr>
                {" "}
                <td
                  style={{
                    flex: 1,
                    paddingRight: 10,
                    textAlign: "end",
                  }}
                >
                  {" "}
                  <label>Amount : </label>{" "}
                </td>{" "}
                <td
                  style={{
                    flex: 2,
                  }}
                >
                  {" "}
                  <Input
                    style={{
                      width: 200,
                    }}
                    name="Amount"
                    value={amount}
                    onChange={(e) => {
                      setAmount(e);
                    }}
                  />{" "}
                </td>{" "}
              </tr>{" "}
              <tr>
                {" "}
                <td
                  style={{
                    flex: 1,
                    paddingRight: 10,
                    textAlign: "end",
                  }}
                >
                  {" "}
                  <label>Category : </label>{" "}
                </td>{" "}
                <td
                  style={{
                    flex: 2,
                  }}
                >
                  {" "}
                  <SelectPicker
                    searchable={false}
                    cleanable={false}
                    data={categories}
                    appearance="default"
                    placeholder="Select Category"
                    style={{
                      width: 220,
                    }}
                    value={category}
                    onSelect={(e) => {
                      setCategory(e);
                    }}
                  />{" "}
                </td>{" "}
              </tr>{" "}
              <tr>
                {" "}
                <td
                  style={{
                    flex: 1,
                    paddingRight: 10,
                    textAlign: "end",
                  }}
                >
                  {" "}
                  <label>Payment Mode : </label>{" "}
                </td>{" "}
                <td
                  style={{
                    flex: 2,
                  }}
                >
                  {" "}
                  <SelectPicker
                    searchable={false}
                    cleanable={false}
                    data={[
                      {
                        id: 1,
                        value: "Cash",
                        label: "Cash",
                      },

                      {
                        id: 2,
                        value: "Bank",
                        label: "Bank",
                      },
                    ]}
                    appearance="default"
                    placeholder="Select payment mode"
                    style={{
                      width: 200,
                    }}
                    key="id"
                    value={Mode}
                    onSelect={(e) => {
                      setMode(e);
                    }}
                  />{" "}
                </td>{" "}
              </tr>{" "}
              <tr>
                {" "}
                <td
                  style={{
                    flex: 1,
                    paddingRight: 10,
                    textAlign: "end",
                  }}
                >
                  {" "}
                  <label>Payment By : </label>{" "}
                </td>{" "}
                <td
                  style={{
                    flex: 2,
                  }}
                >
                  {" "}
                  <SelectPicker
                    searchable={false}
                    data={usersListPayer}
                    cleanable={false}
                    appearance="default"
                    placeholder="Select payer"
                    defaultValue={selectedName}
                    style={{
                      width: 200,
                    }}
                    key="id"
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      /* console.log(Item);
                        console.log(e); */
                      setselectedName(e);
                      setselectedNameID(Item.key);
                    }}
                    value={selectedName}
                  />{" "}
                </td>{" "}
              </tr>{" "}
              <tr>
                {" "}
                <td
                  style={{
                    flex: 1,
                    paddingRight: 10,
                    textAlign: "end",
                  }}
                >
                  {" "}
                  <label>Desc : </label>{" "}
                </td>{" "}
                <td
                  style={{
                    flex: 2,
                  }}
                >
                  {" "}
                  <Input
                    style={{
                      width: 200,
                    }}
                    name="Description"
                    value={desc}
                    onChange={(e) => {
                      setDesc(e);
                    }}
                  />{" "}
                </td>{" "}
              </tr>{" "}
            </table>{" "}
          </Modal.Body>{" "}
          <Modal.Footer>
            {" "}
            <CalmButton title="Submit" onClick={PayBySupervisor} />{" "}
            <Button onClick={handleClose3} appearance="subtle">
              {" "}
              Cancel{" "}
            </Button>{" "}
          </Modal.Footer>{" "}
        </Modal>{" "}
      </div>{" "}
    </div>
  );
};

export default GetSalary;
