import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  SelectPicker,
  DatePicker,
  useToaster,
  Notification,
  IconButton,
  Modal,
  AutoComplete,
  InputGroup,
  Stack,
} from "rsuite";
import Header from "../component/Header";
import SearchIcon from "@rsuite/icons/Search";
import TrashIcon from "@rsuite/icons/Trash";
import { confirmAlert } from "react-confirm-alert"; // Import
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import axios from "axios";
import Table from "react-bootstrap/Table";
import moment from "moment";
import * as XLSX from "xlsx";
import { AdminID } from "../component/data";
import { useSelector } from "react-redux";
import { lastDay } from "../Constants";
import ExportIcon from "@rsuite/icons/Export";
import sendEmail from "../component/sendEmail";
import { Table2XLSX } from "../functions";
import { PictureAsPdf } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));
const OtherLabours = () => {
  const displayName = useSelector(
    (state) => state.nameReducer.user.userDetails
  );
  const Type = displayName.Type;
  const [open, setOpen] = React.useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [LabourList, setLabourList] = useState([]);
  const [dataList, setdataList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [filteredTotal, setFilteredTotal] = useState(0);
  const [processing, setProcessing] = useState(false);
  const toaster = useToaster();
  const [attendance, setAttendance] = useState("");
  const [sitename, setSitename] = useState("");
  const [searchText, setSearchText] = useState("");
  /* const [films1, setFilms1] = useState([]); */
  const [siteList, setSiteList] = useState([]);
  const [amnt, setAmnt] = useState(0);
  const [labourName, setLabourName] = useState("");
  const [rate, setRate] = useState("");
  const [desc, setDesc] = useState("");
  const [date, setDate] = useState(new Date());
  const navigate = useNavigate();
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getLabourList();
      getSitesList();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  const column = [
    { field: "Date", title: "Date" },
    { field: "Name", title: "Name" },
    { field: "PerDayRate", title: "PerDayRate" },
    { field: "Attendance", title: "Attendance" },
    { field: "Total", title: "Total" },
    { field: "Sitename", title: "Sitename" },
  ];

  const columnUser = [
    { field: "Date", title: "Date" },
    { field: "Name", title: "Name" },
    { field: "Attendance", title: "Attendance" },
    { field: "PerDayRate", title: "PerDayRate" },
    { field: "Total", title: "Total" },
    { field: "Sitename", title: "Sitename" },
  ];

  function compare(a, b) {
    let nameA = a.toUpperCase();
    let nameB = b.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }
  const getLabourList = () => {
    setLabourList([]);
    axios
      .get("GetAllOtherWorkers.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          const test = data1.Data.map(({ WorkerName }) => WorkerName);
          // console.log(test);
          setLabourList(test);
        }
        if (data1.status === 0) {
          setLabourList([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getSitesList = () => {
    axios
      .get("GetSitesForOtherLabour.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          const test = data1.Data.map(({ label }) => label);
          // console.log(test);
          setSiteList(test);
        }
        if (data1.status === 0) {
          setSiteList([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    getSitesList();
    setOpen(true);
  };

  const handleSearchforLabour = () => {
    let date = new Date(selectedMonth);
    let firstDay = moment(
      new Date(date.getFullYear(), date.getMonth(), 1)
    ).format("YYYY-MM-DD");
    let lastDay = moment(
      new Date(date.getFullYear(), date.getMonth() + 1, 0)
    ).format("YYYY-MM-DD");
    //        console.log(firstDay);
    //      console.log(lastDay);
    if (selectedMonth === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Month
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    const datatoSend = { Month: lastDay };
    console.log(datatoSend);
    axios
      .post("GetAllDataForOtherWorker.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setdataList(data1.Data);
          setFilteredList(data1.Data);
          setAmnt(data1.Total);
          setFilteredTotal(data1.Total);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              No records
            </Notification>,
            { placement: "topEnd" }
          );
          setdataList([]);
          setFilteredList([]);
          setAmnt(0);
        }
      })
      .catch(function (error) {
        console.log(error);
        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            {error}
          </Notification>,
          { placement: "topEnd" }
        );
        setdataList([]);
        setAmnt(0);
      });
  };

  useEffect(() => {
    var isSubscribed = true;
    if (isSubscribed) {
      if (searchText === "") {
        setFilteredList(dataList);
        setFilteredTotal(amnt);
      } else {
        let filterData = dataList.filter((item) =>
          item.Sitename.toLowerCase().includes(searchText)
        );
        let total = 0;
        filterData.map((item) => {
          total = parseInt(item.Amount) + total;
        });
        setFilteredList(filterData);
        setFilteredTotal(total);
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [searchText]);

  const AddOtherLabour = () => {
    axios
      .post("MarkAttendanceForOther.php", {
        Labour: labourName,
        Attendance: attendance || 0,
        Date: moment(new Date(date)).format("YYYY-MM-DD"),
        AddedBy: AdminID,
        Rate: rate,
        Supervisor: AdminID,
        Notes: desc,
        Sitename: sitename,
      })
      .then((a) => {
        const result = a.data;
        //console.log(result);
        if (result.status === 1) {
          toaster.push(
            <Notification type="success" header="Added" duration={1000}>
              Added Successfully
            </Notification>,
            { placement: "topEnd" }
          );
          handleClose();
          handleSearchforLabour();
        }
        if (result.status === 2) {
        }
        if (result.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              Something went wrong, please try again later.
            </Notification>,
            { placement: "topEnd" }
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleDelete = (id, data = { Name: "", Date: "", Attendance: "" }) => {
    if (id === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Something went wrong.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    confirmAlert({
      title: "Confirm?",
      message: `Are you sure to delete ${data.Name}'s Attendance on ${data.Date}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const datatoSend = { ID: id };
            console.log(datatoSend);
            axios
              .post("DeleteFromOtherWorker.php", datatoSend)
              .then(function (response) {
                const data1 = response.data;
                if (data1.status === 1) {
                  handleSearchforLabour();
                  toaster.push(
                    <Notification
                      type="success"
                      header="Deleted"
                      duration={1000}
                    >
                      Deleted successfully
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "magharamsuthar41@gmail.com",
                    `Deleted ${data.Name}'s Attendance ${data.Attendance} on ${data.Date}.`,
                    `Deleted from Other Workers(${data.Name}) `,
                    "Successfully deleted"
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    `Deleted ${data.Name}'s Attendance on ${data.Date}.`,
                    `Deleted from Other Workers(${data.Name}) `,
                    "Successfully deleted"
                  );
                }
                if (data1.status === 0) {
                  toaster.push(
                    <Notification type="error" header="Error" duration={1000}>
                      Something went wrong. Please refresh and try again later.
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    "Something went wrong. Please refresh and try again later.",
                    `Delete from Other Workers`,
                    "Error in Deleting from Other Workers"
                  );
                }
              })
              .catch(function (error) {
                console.log(error);
                toaster.push(
                  <Notification type="error" header="Error" duration={1000}>
                    {error}
                  </Notification>,
                  { placement: "topEnd" }
                );
                sendEmail(
                  "akv7@ymail.com",
                  error,
                  `Delete from Other Worker(${data.Name})`,
                  "Error in Deleting from Other Worker"
                );
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>Manage Attendance</h4>
      </Header>
      <div className="InputContainer">
        <Stack spacing={10} style={{ marginBottom: 15, paddingLeft: 10 }}>
          <label style={{ textAlign: "right" }}>Month:</label>
          <DatePicker
            oneTap
            format="MMM-yyyy"
            cleanable={false}
            defaultValue={lastDay}
            ranges={[]}
            onSelect={(e) => setSelectedMonth(e)}
            style={{ width: 130 }}
          />

          <IconButton
            onClick={handleSearchforLabour}
            loading={processing}
            icon={<SearchIcon />}
          >
            Refresh
          </IconButton>

          <IconButton
            color="green"
            appearance="primary"
            onClick={handleOpen}
            loading={processing}
            icon={<AddOutlineIcon />}
          >
            Add
          </IconButton>

          <IconButton
            color="red"
            appearance="primary"
            onClick={() => navigate("/Main/GeneratePDF")}
            loading={processing}
            icon={<PictureAsPdf />}
          ></IconButton>

          {Type === "Admin" && (
            <div>
              {" "}
              <b>Total</b> :{" "}
              {Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 2,
              }).format(filteredTotal)}
            </div>
          )}
        </Stack>
      </div>

      <div
        className="InputContainer"
        style={{ overflow: "auto", maxHeight: 550 }}
      >
        {Type === "Admin" ? (
          <Table
            striped
            bordered
            hover
            id="TableData"
            style={{ position: "relative", maxHeight: 550 }}
          >
            <thead>
              <tr>
                {column.map((a) => (
                  <th
                    key={a.field}
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "grey",
                    }}
                  >
                    {a.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredList.map((a) => (
                <tr key={a.SrNo}>
                  <td>{a.Date}</td>
                  <td style={{ textTransform: "capitalize" }}>{a.Name}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.PerDayRate)}
                  </td>
                  <td>{a.Attendance}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Amount)}
                  </td>
                  <td style={{ textTransform: "capitalize" }}>{a.Sitename}</td>
                  <td width={60}>
                    <IconButton
                      icon={<TrashIcon />}
                      color="red"
                      appearance="primary"
                      circle
                      onClick={() =>
                        handleDelete(a.SrNo, {
                          Name: a.Name,
                          Date: a.Date,
                          Attendance: a.Attendance,
                        })
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Table striped bordered hover id="TableData">
            <thead>
              <tr>
                {columnUser.map((a) => (
                  <th key={a.field}>{a.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataList.map((a) => (
                <tr key={a.SrNo}>
                  <td>{a.Date}</td>
                  <td style={{ textTransform: "capitalize" }}>{a.Name}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.PerDayRate)}
                  </td>
                  <td>{a.Attendance}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Amount)}
                  </td>
                  <td style={{ textTransform: "capitalize" }}>{a.Sitename}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
      <div>
        <Modal open={open} onClose={handleClose} size="xs">
          <Modal.Header>
            <Modal.Title>Track Other Workers</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table
              style={{ borderCollapse: "separate", borderSpacing: "0 18px" }}
            >
              <tr style={{ marginBottom: 10, marginTop: 10 }}>
                <td
                  style={{
                    flex: 1,
                    paddingRight: 12,
                    textAlign: "end",
                  }}
                >
                  <label style={{ textAlign: "right" }}>Name :</label>
                </td>
                <td style={{ flex: 2 }}>
                  <AutoComplete
                    placeholder="Enter or select worker's name"
                    data={LabourList}
                    value={labourName}
                    onChange={(e) => setLabourName(e)}
                    style={{ width: 240 }}
                  />
                </td>
              </tr>
              <tr style={{ marginBottom: 10, marginTop: 10 }}>
                <td style={{ flex: 1, paddingRight: 12, textAlign: "end" }}>
                  <label style={{ textAlign: "right" }}>Rate :</label>
                </td>
                <td style={{ flex: 2 }}>
                  <InputGroup style={{ width: "240px" }}>
                    <InputGroup.Addon>(₹)</InputGroup.Addon>
                    <Input
                      placeholder="Per day charges"
                      value={rate}
                      onChange={(e) => {
                        setRate(e);
                      }}
                    />
                    <InputGroup.Addon>.00</InputGroup.Addon>
                  </InputGroup>
                </td>
              </tr>
              <tr style={{ marginBottom: 10, marginTop: 10 }}>
                <td style={{ flex: 1, paddingRight: 12, textAlign: "end" }}>
                  <label style={{ textAlign: "right" }}>Attendance :</label>
                </td>
                <td style={{ flex: 2 }}>
                  <Input
                    style={{ width: 150 }}
                    name="Attendance"
                    placeholder="Attendance"
                    value={attendance}
                    onChange={(e) => {
                      setAttendance(e);
                    }}
                  />
                </td>
              </tr>
              <tr style={{ marginBottom: 10, marginTop: 10 }}>
                <td style={{ flex: 1, paddingRight: 12, textAlign: "end" }}>
                  <label style={{ textAlign: "right" }}>Date :</label>
                </td>
                <td style={{ flex: 2 }}>
                  <DatePicker
                    cleanable={false}
                    appearance="default"
                    placeholder="Select Date"
                    format="dd-MMM-yyyy"
                    oneTap
                    style={{ width: 150 }}
                    value={date}
                    onChange={(e) => {
                      console.log(e);
                      setDate(e);
                    }}
                  />
                </td>
              </tr>
              <tr style={{ marginBottom: 10, marginTop: 10 }}>
                <td style={{ flex: 1, paddingRight: 12, textAlign: "end" }}>
                  <label style={{ textAlign: "right" }}>Site :</label>
                </td>
                <td style={{ flex: 2 }}>
                  {/* <SelectPicker
                    cleanable={false}
                    data={siteList}
                    appearance="default"
                    placeholder="Select Site"
                    style={{ width: 200 }}
                    key="id"
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      setSitename(e);
                    }}
                    value={sitename}
                  /> */}
                  <AutoComplete
                    placeholder="Enter or select site name"
                    data={siteList}
                    value={sitename}
                    onChange={(e) => setSitename(e)}
                    style={{ width: 240 }}
                  />
                </td>
              </tr>
              <tr style={{ marginBottom: 10, marginTop: 10 }}>
                <td style={{ flex: 1, paddingRight: 12, textAlign: "end" }}>
                  <label style={{ textAlign: "right" }}>Additional :</label>
                </td>
                <td style={{ flex: 2 }}>
                  <Input
                    name="textarea"
                    placeholder="Additional"
                    rows={1}
                    accepter={Textarea}
                    value={desc}
                    style={{ resize: "none", width: 240 }}
                    onChange={(e) => setDesc(e)}
                  />
                </td>
              </tr>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={AddOtherLabour} appearance="primary">
              Submit
            </Button>
            <Button onClick={handleClose} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default OtherLabours;
