import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";

import { useToaster, Notification } from "rsuite";
import { SET_USER } from "./redux/type";
import { OS, currentBrowser } from "../functions";
import "./LoginNew.css";
import axios from "../Axios/default";
export default function LoginNew() {
  const toaster = useToaster();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");

  const [ip, setIP] = useState("");
  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    console.log(res.data);
    setIP(res.data.ip);
  };

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) getData();
    return () => {
      isSubscribed = false;
    };
  }, []);

  function handleLogin(e) {
    e.preventDefault();
    const OperatingSystem = OS(window);
    const browser = currentBrowser(window);
    console.log(OperatingSystem);
    console.log(browser);
    axios
      .post("Login1.php", {
        Email: user,
        Password: password,
        IP: ip,
        OS: OperatingSystem,
        Browser: browser,
      })
      .then((response) => {
        const result = response.data;
        console.log(result.Data);
        if (result.Data.Type !== "Admin" && result.Data.Type !== "SuperAdmin") {
          console.log("Only Admins allowed");
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              Only Admins allowed. Access denied
            </Notification>,
            { placement: "topEnd" }
          );
        } else {
          toaster.push(
            <Notification type="success" header="Success" duration={1000}>
              Access granted
            </Notification>,
            { placement: "topEnd" }
          );
          sessionStorage.setItem("access", result.Data.SessionID);

          dispatch({
            type: SET_USER,
            payload: {
              Name: result.Data.Name,
              Email: result.Data.Email,
              UniqueID: result.Data.UniqueID,
              SessionID: result.Data.SessionID,
              userDetails: result.Data,
            },
          });
          navigate("/Main");
        }
      });
  }
  return (
    <>
      <section className="login">
        {" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span> <span></span> <span></span>
        <div className="signin">
          <div className="content">
            <h2>Sign In</h2>

            <form onSubmit={handleLogin} className="form">
              <div className="form">
                <div className="inputBox">
                  <input
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                    type="text"
                    required
                  />{" "}
                  <i>Username</i>
                </div>

                <div className="inputBox">
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    required
                  />{" "}
                  <i>Password</i>
                </div>

                <div className="links">
                  {" "}
                  <a href="none">Forgot Password</a>
                </div>

                <div className="inputBox">
                  {/* <input type="submit" value="Login" onSubmit={handleLogin} /> */}
                  <button onClick={handleLogin}>Login</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
