import React, { useState } from "react";
import {
  Button,
  DatePicker,
  useToaster,
  Notification,
  Form,
  Radio,
  RadioGroup,
  SelectPicker,
  IconButton,
  Modal,
} from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";
import Header from "../component/Header";

import moment from "moment";
import sendEmail from "../component/sendEmail";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import Table from "react-bootstrap/Table";
import SearchIcon from "@rsuite/icons/Search";
import axios from "axios";
import { firstDay } from "../Constants";
import { Image } from "react-bootstrap";
import { Table2XLSX } from "../functions";

const ViewAll = () => {
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState({});
  const [radio, setRadio] = useState("A");
  const [open, setOpen] = React.useState(false);
  const [selectedDate, setselectedDate] = useState(new Date());
  const [selectedName, setselectedName] = useState("");
  const [selectedNameID, setselectedNameID] = useState("");

  const [datalist, setDataList] = useState([]);
  const [credit, setCredit] = useState(0);
  const [debit, setDebit] = useState(0);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [usersList, setUsersList] = useState([]);
  const [selectedSite, setselectedSite] = useState("");
  const [billPath, setBillPath] = useState("");
  const [siteList, setSiteList] = useState([]);
  const [billID, setBillID] = useState("");

  const toaster = useToaster();

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [receiverList, setReceiverList] = useState([
    {
      label: "Eugenia",
      value: "Eugenia",
      role: "Master",
    },
    {
      label: "Kariane",
      value: "Kariane",
      role: "Master",
    },
    {
      label: "Louisa",
      value: "Louisa",
      role: "Master",
    },
  ]);

  const getRecieversList = () => {
    setReceiverList([]);
    axios
      .get("GetAllBillers.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          //setDataList(data1.Data)
          data1.Data.forEach((r) => {
            setReceiverList((a) => {
              return [...a, { id: r.UniqueID, value: r.Name, label: r.Name }];
            });
          });
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getUsersList = () => {
    setUsersList([]);
    axios
      .get("GetUsers.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          //setDataList(data1.Data)
          data1.Data.forEach((r) => {
            setUsersList((a) => {
              return [...a, { id: r.UniqueID, value: r.Name, label: r.Name }];
            });
          });
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getSitesList = () => {
    axios
      .get("GetSitesForDropdown.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setSiteList(data1.Data);
        }
        if (data1.status === 0) {
          setSiteList([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [column, setColumn] = useState([
    { field: "Name", title: "Name" },
    { field: "Date", title: "Date" },
    { field: "Amount", title: "Amount" },
    { field: "Category", title: "Category" },
    { field: "Description", title: "Description" },
    { field: "RecipientType", title: "PayeeType" },
    { field: "SavedBy", title: "SavedBy" },
    { field: "Delete", title: "Delete" },
  ]);

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      if (radio === "A") handleGetCurrentMonth();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  const handleGetCurrentMonth = () => {
    axios
      .get("GetTransactionsForCurrentMonth.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setDataList(data1.Data);
          setTotal(data1.Sum);
          setCount(data1.Count);
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleMonthwise = () => {
    let datatoSend = { Month: selectedDate };
    axios
      .post("GetTransactionsForMonth.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setDataList(data1.Data);
          setTotal(data1.Sum);
          setCount(data1.Count);
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handlePartywise = () => {
    let datatoSend = { NameID: selectedNameID };
    axios
      .post("GetTransactionsByParty.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setDataList(data1.Data);
          setTotal(data1.Sum);
          setCount(data1.Count);
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSupwise = () => {
    setCredit(0);
    setDebit(0);
    setColumn([
      { field: "Name", title: "Name" },
      { field: "Date", title: "Date" },
      { field: "Amount", title: "Amount" },
      { field: "Category", title: "Category" },
      { field: "Description", title: "Description" },
      { field: "TransactionType", title: "Type" },
      { field: "SavedBy", title: "SavedBy" },
    ]);

    let datatoSend = { NameID: selectedNameID, Month: selectedDate };
    axios
      .post("GetTransactionBySup.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setDataList(data1.Data);
          setTotal(data1.Sum);
          setCredit(data1.IncomeSum);
          setDebit(data1.ExpenseSum);
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSitewise = () => {
    let datatoSend = { Sitename: selectedSite };
    axios
      .post("GetTransactionBySite.php", datatoSend)
      .then(function (response) {
        const data = response.data;
        if (data.status === 1) {
          setDataList(data.Data);
          setTotal(data.Sum);
          setCount(data.Count);
        }
        if (data.status === 0) {
          setDataList([]);
          setTotal(0);
          setCount(0);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleRadio = (e) => {
    setTotal(0);
    setCount(0);
    setRadio(e);
    if (e === "A") {
      handleGetCurrentMonth();
    }
    if (e === "B") {
      handleMonthwise();
    }
    if (e === "C") {
      setDataList([]);
      setselectedName("");
      getRecieversList();
    }
    if (e === "D") {
      setselectedName("");
      setDataList([]);
      getUsersList();
    }
    if (e === "E") {
      getSitesList();
      setselectedSite("");
      setDataList([]);
      getUsersList();
    }
  };

  function compare(a, b) {
    let nameA = a.toUpperCase();
    let nameB = b.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  const handleDelete = (id, data = { Name: "", Date: "", Amount: "" }) => {
    if (id === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Something went wrong.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    let text;
    //confirmAlert(options);
    confirmAlert({
      title: "Confirm?",
      message: `Are you sure to delete ${data.Name}'s Payment on ${data.Date} for transaction of Amount ${data.Amount}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const datatoSend = { ID: id };
            console.log(datatoSend);
            axios
              .post("DeleteFromTransaction.php", datatoSend)
              .then(function (response) {
                const data1 = response.data;
                if (data1.status === 1) {
                  toaster.push(
                    <Notification
                      type="success"
                      header="Deleted"
                      duration={1000}
                    >
                      Deleted successfully
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "magharamsuthar41@gmail.com",
                    `Deleted ${data.Name}'s Payment on ${data.Date} for Amount ${data.Amount}`,
                    `Deleted from Supplier(${data.Name}) Transaction`,
                    "Successfully deleted"
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    `Deleted ${data.Name}'s Payment on ${data.Date} for Amount Amount ${data.Amount} ?`,
                    `Deleted from Supplier(${data.Name}) Transaction`,
                    "Successfully deleted"
                  );
                }
                if (data1.status === 0) {
                  toaster.push(
                    <Notification type="error" header="Error" duration={1000}>
                      Something went wrong. Please refresh and try again later.
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    "Something went wrong. Please refresh and try again later.",
                    `Delete from Supplier(${data.Name}) Transaction`,
                    "Error in Deleting from Supplier Transaction"
                  );
                }
              })
              .catch(function (error) {
                console.log(error);
                toaster.push(
                  <Notification type="error" header="Error" duration={1000}>
                    {error}
                  </Notification>,
                  { placement: "topEnd" }
                );
                sendEmail(
                  "akv7@ymail.com",
                  error,
                  `Delete from Supplier(${data.Name}) Transaction`,
                  "Error in Deleting from Supplier Transaction"
                );
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  function ShowBill(bill, billdata) {
    handleOpen();
    setBillPath(`https://Downloads.ashaelectricals.in/upload/${bill}`);
    setBillID(billdata.ID);
    setData(billdata);
  }

  function RemoveBill() {
    console.log(data);
    confirmAlert({
      title: "Confirm?",
      message: `Are you sure to remove this bill?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const datatoSend = { ID: billID };

            axios
              .post("RemoveBillCopy.php", datatoSend)
              .then(function (response) {
                const data1 = response.data;
                if (data1.status === 1) {
                  toaster.push(
                    <Notification
                      type="success"
                      header="Deleted"
                      duration={1000}
                    >
                      Deleted successfully
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "magharamsuthar41@gmail.com",
                    `Deleted ${data.Name}'s Bill Copy on ${data.Date} for Amount ${data.Amount}`,
                    `Deleted from Material(${data.Name}) Transaction`,
                    "Successfully deleted"
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    `Deleted ${data.Name}'s Bill Copy on ${data.Date} for Amount ${data.Amount} ?`,
                    `Deleted from Material(${data.Name}) Transaction`,
                    "Successfully deleted"
                  );
                  handleSitewise();
                }
                if (data1.status === 0) {
                  toaster.push(
                    <Notification type="error" header="Error" duration={1000}>
                      Something went wrong. Please refresh and try again later.
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    "Something went wrong. Please refresh and try again later.",
                    `Delete from Supplier(${data.Name}) Transaction`,
                    "Error in Deleting from Supplier Transaction"
                  );
                }
              })
              .catch(function (error) {
                console.log(error);
                toaster.push(
                  <Notification type="error" header="Error" duration={1000}>
                    {error}
                  </Notification>,
                  { placement: "topEnd" }
                );
                sendEmail(
                  "akv7@ymail.com",
                  error,
                  `Delete from Supplier(${data.Name}) Transaction`,
                  "Error in Deleting from Supplier Transaction"
                );
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>
          {radio === "A" &&
            `Current Month (${moment(firstDay).format("MMM-YYYY")})`}
          {radio === "B" && `${moment(selectedDate).format("MMM-YYYY")}`}
          {radio === "C" &&
            (selectedName ? `Party Wise - ${selectedName}` : "Party Wise")}
          {radio === "D" &&
            (selectedName
              ? `Supervisor Wise - ${selectedName}`
              : "Supervisor Wise")}
          {radio === "E" &&
            (selectedSite ? `Material - (${count})` : "Material")}
        </h4>
      </Header>
      <div className="InputContainer">
        <Form.Group controlId="radioList">
          <RadioGroup
            name="radioList"
            inline
            appearance="picker"
            defaultValue="A"
            onChange={(e) => {
              handleRadio(e);
            }}
            value={radio}
          >
            <Radio value="A">Current Month</Radio>
            <Radio value="B">Month Wise</Radio>
            <Radio value="C">Party View</Radio>
            <Radio value="D">Supervisor</Radio>
            <Radio value="E">Material</Radio>
          </RadioGroup>
          &nbsp; &nbsp; &nbsp;
          <Table2XLSX />
          <hr />
        </Form.Group>

        <Form layout="inline">
          {radio === "A" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifuContent: "space-between",
                }}
              >
                <div style={{ flex: 1 }}>
                  <Button
                    color="blue"
                    appearance="default"
                    onClick={handleGetCurrentMonth}
                  >
                    <SearchIcon style={{ margin: "5px" }} /> Search
                  </Button>
                </div>
                <div style={{ flex: 1, justifyContent: "center" }}>
                  <div>
                    {`Total Amount : ${Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(total)}`}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    {`No of Transactions : ${count}`}
                  </div>
                </div>
              </div>
            </>
          )}
          {radio === "B" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifuContent: "space-between",
                }}
              >
                <div style={{ flex: 1 }}>
                  <Form.Group>
                    <Form.ControlLabel>Date:</Form.ControlLabel>
                    <DatePicker
                      oneTap
                      cleanable={false}
                      format="MMM-yyyy"
                      defaultValue={firstDay}
                      ranges={[]}
                      onSelect={(e) => setselectedDate(e)}
                      style={{ width: 200 }}
                    />
                  </Form.Group>
                  <Button
                    color="blue"
                    appearance="default"
                    onClick={handleMonthwise}
                  >
                    <SearchIcon style={{ margin: "5px" }} /> Search
                  </Button>
                </div>
                <div style={{ flex: 1, justifyContent: "center" }}>
                  <div>
                    {`Total Amount : ${Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(total)}`}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    {`No of Transactions : ${count}`}
                  </div>
                </div>
              </div>
            </>
          )}

          {radio === "C" && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifuContent: "space-between",
              }}
            >
              <div style={{ flex: 1 }}>
                <Form.Group>
                  <Form.ControlLabel>Party:</Form.ControlLabel>
                  <SelectPicker
                    cleanable={false}
                    data={receiverList}
                    appearance="default"
                    placeholder="Select Party"
                    style={{ width: 224 }}
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      console.log(Item);
                      setselectedName(e);
                      setselectedNameID(Item.id);
                    }}
                  />
                </Form.Group>
                <Button
                  color="blue"
                  appearance="default"
                  onClick={handlePartywise}
                >
                  <SearchIcon style={{ margin: "5px" }} /> Search
                </Button>
              </div>
              <div style={{ flex: 1, justifyContent: "center" }}>
                <div>
                  {`Total Amount : ${Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(total)}`}
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  {`No of Transactions : ${count}`}
                </div>
              </div>
            </div>
          )}

          {radio === "D" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifuContent: "space-between",
                }}
              >
                <div style={{ flex: 1 }}>
                  <Form.Group>
                    <Form.ControlLabel>Supervisor:</Form.ControlLabel>
                    &nbsp; &nbsp; &nbsp;
                    <SelectPicker
                      cleanable={false}
                      data={usersList}
                      appearance="default"
                      placeholder="Select Supervisor"
                      style={{ width: 200 }}
                      key="id"
                      sort={() => {
                        return (a, b) => {
                          return compare(a.value, b.value);
                        };
                      }}
                      onSelect={(e, Item) => {
                        console.log(Item);
                        setselectedName(e);
                        setselectedNameID(Item.id);
                      }}
                      value={selectedName}
                    />
                    &nbsp; &nbsp; &nbsp;
                    <DatePicker
                      cleanable={false}
                      oneTap
                      format="MMM-yyyy"
                      defaultValue={firstDay}
                      ranges={[]}
                      onSelect={(e) => setselectedDate(e)}
                      style={{ width: 130 }}
                    />
                    &nbsp; &nbsp; &nbsp;
                    <Button
                      color="blue"
                      appearance="default"
                      onClick={handleSupwise}
                    >
                      <SearchIcon style={{ margin: "5px" }} /> Search
                    </Button>
                  </Form.Group>
                </div>
                <div style={{ flex: 1, justifyContent: "center" }}>
                  <div>
                    {`Credit : ${Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(credit)}`}
                    &nbsp;
                    {`Debit : ${Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(debit)}`}
                    &nbsp;
                    <h6
                      style={{
                        display: "inline-block",
                        color: credit < debit ? "red" : "limegreen",
                      }}
                    >
                      {`Balance : ${Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                      }).format(credit - debit)}`}
                    </h6>
                  </div>
                </div>
              </div>
            </>
          )}

          {radio === "E" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifuContent: "space-between",
                }}
              >
                <div style={{ flex: 1 }}>
                  <Form.Group>
                    <Form.ControlLabel>Site:</Form.ControlLabel>
                    &nbsp; &nbsp; &nbsp;
                    <SelectPicker
                      cleanable={false}
                      data={siteList}
                      appearance="default"
                      placeholder="Select Site"
                      style={{ width: 200 }}
                      key="id"
                      sort={() => {
                        return (a, b) => {
                          return compare(a.value, b.value);
                        };
                      }}
                      onSelect={(e, Item) => {
                        // console.log(Item);
                        setselectedSite(e);
                      }}
                      value={selectedSite}
                    />
                    &nbsp; &nbsp; &nbsp;
                    {/* <DatePicker
                      oneTap
                      format="MMM-yyyy"
                      defaultValue={firstDay}
                      ranges={[]}
                      onSelect={(e) => setselectedDate(e)}
                      style={{ width: 130 }}
                    />
                    &nbsp; &nbsp; &nbsp; */}
                    <Button
                      color="blue"
                      appearance="default"
                      onClick={handleSitewise}
                    >
                      <SearchIcon style={{ margin: "5px" }} /> Search
                    </Button>
                  </Form.Group>
                </div>
                <div style={{ flex: 1, justifyContent: "center" }}>
                  <div>
                    {`Total : ${Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(total)}`}
                  </div>
                </div>
              </div>
            </>
          )}
        </Form>

        <div>
          <Modal open={open} onClose={handleClose} size="lg">
            <Modal.Header>
              <Modal.Title>Bill </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Image style={{ resize: "contain" }} src={billPath} />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={RemoveBill} appearance="primary">
                Remove Bill
              </Button>
              <Button onClick={handleClose} appearance="subtle">
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>

      <div className="InputContainer">
        <Table id="TableData" striped bordered hover>
          <thead>
            <tr>
              {column.map((a) => (
                <th key={a.field}>{a.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {datalist.map((a) => (
              <tr key={a.SrNo}>
                <td
                  onClick={() =>
                    ShowBill(a.Bill, {
                      Name: a.Name,
                      Date: a.Date,
                      Amount: a.Amount,
                      ID: a.SrNo,
                    })
                  }
                  style={{ cursor: `${a.Bill ? "pointer" : "cursor"}` }}
                >
                  {a.Name}
                </td>
                <td>{a.Date}</td>
                <td>
                  {Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(a.Amount)}
                </td>
                <td>{a.Category}</td>
                <td>{a.Description}</td>
                <td>{radio === "D" ? a.TransactionType : a.RecipientType}</td>
                <td>{a.SavedBy}</td>
                <td width={60}>
                  <IconButton
                    icon={<TrashIcon />}
                    color="red"
                    appearance="primary"
                    circle
                    onClick={() =>
                      handleDelete(a.SrNo, {
                        Name: a.Name,
                        Date: a.Date,
                        Amount: a.Amount,
                      })
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ViewAll;
